import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { functions } from "../../firebase/firebase";

const fetchEndpoint = functions.httpsCallable("fetchEndpoint");

export const fetchBuilding: any = createAsyncThunk(
	"buildings/fetchBuilding",
	async ({ api, id }: { api: string; id: string }) => {
		const response = await fetchEndpoint({ route: `/building/${id}`, api });
		const parsedResponse = JSON.parse(response.data);

		if (parsedResponse?.id) {
			return parsedResponse;
		} else {
			return {};
		}
	}
);

export const buildingsSlice = createSlice({
	name: "buildings",
	initialState: {
		data: {},
		status: "",
		error: "",
	},
	reducers: {},
	extraReducers: {
		[fetchBuilding.pending]: (state, action) => {
			state.status = "loading";
		},
		[fetchBuilding.fulfilled]: (state: any, action: any) => {
			state.status = "succeeded";
			if (action.payload?.id) {
				state.data[action.payload.id] = action.payload;
			}
		},
		[fetchBuilding.rejected]: (state, action) => {
			state.status = "failed";
			state.error = action.error.message;
		},
	},
});

export default buildingsSlice.reducer;
