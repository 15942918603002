import { Grid } from "@material-ui/core";
import * as React from "react";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

import { IDensityPoint } from "../../../interfaces";
import { GlassPaper } from "../../GlassPaper/GlassPaper";
import { DensityDial } from "./DensityDial";

interface IDensityProps {
	name: string;
	// Hierarchy
	id: string;
	buildingId: string;
	floorId: string;
	spaceId: string;
	// Data
	points: IDensityPoint | any[];
	// Selector
	virtualProfile: string;
}

interface IDensityContainerProps {
	densityDevice: IDensityProps;
}

export const Density: React.FC<IDensityContainerProps> = ({
	densityDevice,
}) => {
	// Building data
	const deviceStore = useAppSelector((state: RootState) => state.devices); //devices fetched and stored on selection
	const deviceData = deviceStore.data?.[densityDevice.id];

	// If no device data or the device has no points, return null;
	if (Boolean(!deviceData || !deviceData?.points?.length)) {
		return null;
	}

	if (!deviceData) return null;

	return (
		<GlassPaper style={{ padding: "1rem", height: "100%" }} square>
			<Grid container>
				<Grid item xs={12}>
					<DensityDial deviceData={deviceData} />
				</Grid>
			</Grid>
		</GlassPaper>
	);
};
