import * as React from "react";

// MUI
import { Grid, LinearProgress } from "@mui/material";

// Util
import { find, isEmpty } from "lodash";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { fetchPublic } from "../../features/public/publicSlice";

// Routing
import { useHistory, useLocation, useParams } from "react-router";

// Components
import { Section } from "../../components/Section/Section";
import { Title } from "../../components/Title/Title";
import { GlassDial } from "../../components/GlassDial/GlassDial";
import { getTempColorValue } from "../../util/getColorValue";

// Firebase
import { functions } from "../../firebase/firebase";

const fetchEndpointPublic = functions.httpsCallable("fetchEndpointPublic");

const convertCamel = (str: string) => {
	if (typeof str !== "string") return "";

	return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
		return str.toUpperCase();
	});
};

interface IIAQMetadataPageProps {}

const useQuery = () => {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const IAQMetadataPage: React.FC<IIAQMetadataPageProps> = (props) => {
	const params: any = useParams();
	const query: any = useQuery();
	const history = useHistory();

	const component = query.get("component");
	// const type = query.get("type");
	const resource = query.get("resource");

	const dispatch = useDispatch();

	const publicData = useSelector((state: any) => state.public);
	const publicBuildings = publicData.buildings;

	const buildingId = params?.buildingId;
	const spaceId = params?.spaceId;
	const floorId = params?.floorId;

	const [pollingData, setPollingData]: any = React.useState();

	let resourceData: any = pollingData || publicBuildings?.[buildingId] || {};

	if (resource === "floor") {
		resourceData = find(
			resourceData?.floors,
			(floor: any) => floorId === floor.id
		);

		if (resource === "space") {
			resourceData = find(
				resourceData?.spaces,
				(space: any) => spaceId === space.id
			);
		}
	}

	//! Device Polling Start													//
	React.useEffect(() => {
		const interval = setInterval(() => {
			if (Boolean(resourceData?.id && buildingId)) {
				fetchEndpointPublic({ route: `buildings/${buildingId}` })
					.then((res: any) => {
						if (res?.data) {
							setPollingData(JSON.parse(res.data));
						}
					})
					.catch((e: any) => {
						console.log(e);
					});
			}
		}, 10000);

		return () => {
			setPollingData(null);
			clearInterval(interval);
		};

		// eslint-disable-next-line
	}, [resourceData?.id]);
	//! Device Polling End														//

	// ! DEP: PAGE REFRESH TO TAKE CARE OF OVERFLOW
	React.useEffect(() => {
		const refreshInterval = setInterval(() => {
			window.location.reload();
		}, 86400000);

		return () => {
			clearInterval(refreshInterval);
		};
	}, []);
	// ! DEP: PAGE REFRESH TO TAKE CARE OF OVERFLOW

	React.useEffect(() => {
		if (buildingId) {
			dispatch(fetchPublic({ type: "buildings", id: buildingId }));
		}
		// eslint-disable-next-line
	}, [buildingId, spaceId, floorId]);

	if (!buildingId) {
		history.push("/iaq");
	}

	if (publicData.status === "loading") {
		return <LinearProgress />;
	}

	if (isEmpty(resourceData) || !resourceData?.metadata)
		return (
			<Section>
				<Title title="Cannot find metadata" />
			</Section>
		);

	let metadata = Object.keys(resourceData?.metadata).map((metaKey: string) => ({
		...resourceData?.metadata?.[metaKey],
		component: metaKey,
	}));

	if (component !== "all") {
		metadata = metadata.filter((data: any) => data.component === component);
	}

	return (
		<Grid
			container
			style={{
				padding: "1rem 0",
				height: "100%",
				alignItems: "center",
				justifyContent: "center",
				background: `linear-gradient(-90deg, #8A9AA7 0%, #6E8288 75%)`,
			}}
		>
			{metadata.map((data: any, idx: number) => {
				let value =
					Boolean(data?.component === "airQuality") && Number(data.avg) >= 100
						? 100
						: Number(data.avg);

				let customGradient =
					data?.component === "temperature"
						? getTempColorValue(value)
						: { colorStart: "", colorEnd: "" };

				return (
					<Grid
						key={`metadata_dial_${idx}_${data?.component}`}
						item
						xs={12}
						sm={6}
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<GlassDial
							id={data?.component || String(Math.random())}
							value={value}
							label={`${convertCamel(data?.component)}` || "Device"}
							size={metadata.length < 3 ? 250 : 190}
							fontSize={metadata.length < 3 ? "4rem" : "3.5rem"}
							colorByValue={Boolean(data?.component === "airQuality")}
							customGradient={customGradient}
						/>
					</Grid>
				);
			})}
		</Grid>
	);
};
