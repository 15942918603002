import React, { ReactElement } from "react";

// MUI
import { Typography, makeStyles, createStyles, Theme } from "@material-ui/core";

// Styles
import { useAnimations } from "../../animations";
import clsx from "clsx";

// Images
import BrandIcon from "../../static/icons/tapaIcon.png";
import ErrorIcon from "../../static/icons/errorIcon.png";
import LoaderBackground from "../../static/images/loader.webp";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: "100%",
			width: "100%",
			justifyContent: "center",
			alignItems: "center",
			background: "transparent",
		},
		container: {
			height: 100,
			width: 100,
			margin: "20% auto 0 auto",
		},
		logo: {
			opacity: 0,
		},
	})
);

interface Props {
	title: string | ReactElement | JSX.Element;
	secondary?: string | ReactElement | JSX.Element;
	trigger: boolean;
	error?: { title: string; message: string };
	retry?: JSX.Element;
}

export default function IntroLoader({
	title,
	secondary,
	trigger,
	error,
	retry,
}: Props): ReactElement {
	const classes = useStyles();
	const animations = useAnimations();

	const animationOptions = clsx({
		[animations.fadeIn]: trigger,
		// [animations.fadeOut]: !trigger
	});

	return (
		<div className={classes.root}>
			<div
				className={clsx(classes.container, {
					[animations.rotate]: error && !error.title && !error.message,
				})}
			>
				<img
					className={animationOptions}
					src={error && (error.title || error.message) ? ErrorIcon : BrandIcon}
					alt={"logo"}
					height={"100%"}
					style={{ cursor: "pointer" }}
				/>
			</div>

			{/*  */}
			<Typography
				variant="h4"
				align="center"
				style={{ padding: "2rem", fontWeight: 300 }}
				className={animationOptions}
			>
				{error?.title ? error.title : title}
			</Typography>

			{/* Error message */}
			{error?.message && (
				<Typography
					variant="h6"
					align="center"
					style={{ padding: "2rem", fontWeight: 300 }}
					className={animationOptions}
				>
					{error.message}
				</Typography>
			)}

			{secondary && (
				<Typography
					variant="h6"
					align="center"
					style={{ padding: "2rem", fontWeight: 300 }}
					className={animationOptions}
				>
					{secondary}
				</Typography>
			)}

			{error && (error.message || error.title) && retry ? (
				<div style={{ display: "flex", justifyContent: "center" }}>{retry}</div>
			) : null}
			<div
				style={{
					width: "100%",
					height: "100%",
					backgroundImage: `url(${LoaderBackground})`,
					backgroundRepeat: "no-repeat",
					backgroundAttachment: "fixed",
					backgroundPosition: "center",
					position: "absolute",
					top: 0,
					left: 0,
					opacity: "25%",
					zIndex: -1,
				}}
			/>
		</div>
	);
}
