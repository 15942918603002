import React from "react";

// MUI
import {
	makeStyles,
	Theme,
	Avatar,
	Typography,
	Container,
	Tabs,
	Tab,
	useMediaQuery,
	Divider,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	ListItemSecondaryAction,
} from "@material-ui/core";

// Styles
import { createStyles, withStyles } from "@material-ui/styles";

// Icons
import { ChevronRight, People } from "@material-ui/icons";

// Colors
import { deepOrange } from "@material-ui/core/colors";

// Hooks
import { useSelector } from "react-redux";

// Components
import { GlassPaper } from "../../components/GlassPaper/GlassPaper";
import { GlassCircle } from "../../components/GlassCircle/GlassCircle";

// Util
import { isEmpty } from "lodash";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	container: {
		position: "relative",
		padding: "2rem",
		width: "100%",
		justifyContent: "center",
		display: "flex",
	},
	avatar: {
		position: "absolute",
	},
	// avatar backup color
	orange: {
		color: theme.palette.getContrastText(deepOrange[500]),
		backgroundColor: deepOrange[500],
	},
	profileContainer: {
		position: "absolute",
		top: 80,
	},
	nameContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		margin: "1rem 0",
	},
	tabContainer: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		width: "100%",
		margin: "1rem 0",
	},
	manageContainer: {
		width: "100%",
		padding: "0 2rem",
		margin: "1rem 0",
	},
}));

const CustomTab = withStyles((theme: Theme) =>
	createStyles({
		root: {
			borderRadius: 5,
			minWidth: 72,
			padding: "1rem",
			margin: "0 1rem",
			"&:hover": {
				opacity: 1,
			},
			"&$selected": {
				background: theme.palette.primary.main,
				color: theme.palette.getContrastText(theme.palette.primary.main),
				fontWeight: theme.typography.fontWeightMedium,
			},
		},
		selected: {},
	})
)((props: any) => <Tab {...props} />);

const Profile = () => {
	const classes = useStyles();
	const profile = useSelector((state: any) => state.firebase.profile);

	const mobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

	// State
	const [manageGroups, setManageGroups]: any = React.useState([]);

	const [tab, setTab] = React.useState(1);

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setTab(newValue);
	};

	// Populate Groups
	React.useEffect(() => {
		if (profile?.manage?.groups && !manageGroups.length) {
			setManageGroups(
				Object.keys(profile.manage.groups).map((groupId: any) => ({
					...profile.manage.groups[groupId],
					key: groupId,
				}))
			);
		}
		// eslint-disable-next-line
	}, [profile.isLoaded]);

	// Convenience Vars
	const hasManage = Boolean(
		profile?.manage && !isEmpty(profile.manage) && profile?.manage?.groups
	);

	return (
		<Container maxWidth="md" className={classes.container}>
			{/* Profile */}
			<GlassPaper
				square={mobile}
				style={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					position: "absolute",
					top: 80,
					margin: "2rem 0 10rem 0",
					width: "100%",
					paddingTop: 110,
					paddingBottom: "2rem",
				}}
			>
				{/* Name Container */}
				<div className={classes.nameContainer}>
					<Typography variant="h5">
						{profile.firstName} {profile.lastName}
					</Typography>
					<Typography variant="body1">{profile.email}</Typography>
				</div>

				{/* If user has manage, create Tabs */}
				{hasManage && (
					<div className={classes.tabContainer}>
						<Tabs
							value={tab}
							indicatorColor="primary"
							textColor="primary"
							onChange={handleChange}
							TabIndicatorProps={{ hidden: true }}
						>
							<CustomTab label="Control" disabled />
							<CustomTab label="Manage" />
						</Tabs>

						<Divider style={{ width: "100%", marginTop: "1rem" }} />
					</div>
				)}

				{Boolean(hasManage && tab === 1 && manageGroups.length) && (
					<div className={classes.manageContainer}>
						<Typography variant="h6" style={{ width: "100%" }}>
							Tapa Manage Details
						</Typography>
						<List>
							<ListItem button>
								<ListItemIcon>
									<People />
								</ListItemIcon>
								<ListItemText
									primary={"Groups"}
									secondary={`View ${manageGroups.length} groups`}
								/>
								<ListItemSecondaryAction>
									<ChevronRight />
								</ListItemSecondaryAction>
							</ListItem>
							<Divider />
						</List>
					</div>
				)}
			</GlassPaper>

			{/* Avatar */}
			<div className={classes.avatar}>
				<GlassCircle style={{ padding: 10 }}>
					<Avatar
						src={profile.avatar}
						alt={profile.firstName}
						className={classes.orange}
						style={{ height: 150, width: 150 }}
					>
						{profile.firstName[0]}
						{profile.lastName[0]}
					</Avatar>
				</GlassCircle>
			</div>
		</Container>
	);
};

export default Profile;
