import React, { ReactElement } from "react";

// MUI
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	AppBar,
	Button,
	Divider,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Toolbar,
	Typography,
	styled,
	useTheme,
	ButtonGroup,
} from "@mui/material";

// Icons
import { ArrowBack, Business, ExpandMore } from "@material-ui/icons";

import { TableRows, ViewCompact } from "@mui/icons-material";

// Hooks
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// Util
import { orderBy } from "lodash";

// Redux
import { fetchPublic } from "../../features/public/publicSlice";

// Components
import { IAQDeviceMenu } from "./IAQDeviceMenu";
import { IAQMetadataTable } from "./IAQMetadataTable";
import { IAQMetadataMenu } from "./IAQMetadataMenu";
import { Section } from "../../components/Section/Section";
import { Title } from "../../components/Title/Title";

const CustomizedListItem = styled(ListItem)`
	.MuiListItemSecondaryAction-root {
		width: 66%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: right;
	}
`;

interface Props {}

export const getDialRoute = ({
	pathname,
	resource,
	component,
	type,
}: {
	pathname: string;
	resource: string;
	component: string;
	type: string;
}) => {
	return {
		pathname,
		search: `resource=${resource}&component=${component}&type=${type}`,
	};
};

export default function IAQBuildingTable(): ReactElement | null {
	const fieldRef: any = React.useRef<HTMLInputElement>(null);
	const theme = useTheme();

	const history = useHistory();
	const params: any = useParams();
	const dispatch = useDispatch();

	const publicStore = useSelector((state: any) => state.public);

	const buildings = publicStore.buildings;
	const building = buildings[params.buildingId];

	// .. Panels					//
	const [expanded, setExpanded] = React.useState<string | false>(false);
	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	// .. Fetch building data		//
	React.useEffect(() => {
		if (!building && params.buildingId && publicStore.status !== "loading") {
			dispatch(fetchPublic({ id: params.buildingId, type: "buildings" }));
		}
		// eslint-disable-next-line
	}, [params.buildingId]);

	// .. Scroll to top				//
	React.useEffect(() => {
		if (fieldRef) {
			fieldRef?.current?.scrollIntoView();
		}
	}, [params.buildingId]);

	// .. Fetching					//
	if (publicStore.status === "loading") {
		return <LinearProgress />;
	}

	// ! Error handling				//
	if (!building) {
		return (
			<Section>
				<Title
					title="Building data not found"
					button={<Button onClick={() => history.push(`/iaq`)}>Go Back</Button>}
				/>
			</Section>
		);
	}

	return (
		<div ref={fieldRef}>
			{/* //1 		Building Appbar		 */}
			<AppBar>
				<Toolbar>
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						sx={{ mr: 2 }}
						onClick={() => history.push(`/iaq`)}
					>
						<ArrowBack />
					</IconButton>
					<Typography variant="h6" color="inherit" component="div">
						{`${building?.name} Floors` || ""}
					</Typography>
				</Toolbar>
			</AppBar>

			{/* //1 		Building Metadata	 */}
			<Section style={{ borderRadius: 0, marginTop: "4rem" }}>
				<Title
					title="Building Metadata"
					icon={<Business style={{ marginRight: ".5rem" }} />}
				/>
				<IAQMetadataTable
					size="small"
					metadata={building?.metadata}
					location={{ buildingId: building?.id }}
					resource="building"
				/>
			</Section>

			{/* //2 		Floor Accordions	 */}
			<Section
				style={{
					borderRadius: 0,
				}}
			>
				<Title
					title="Floors"
					icon={<TableRows style={{ marginRight: ".5rem" }} />}
				/>
				{orderBy(building?.floors, ["name"]).map((floor: any) => {
					return (
						<Accordion
							key={floor?.id}
							style={{
								boxShadow:
									expanded === floor?.id ? theme.shadows[7] : "inherit",
							}}
							expanded={expanded === floor?.id}
							onChange={handleChange(floor?.id)}
							TransitionProps={{ unmountOnExit: true }}
							disabled={!Boolean(floor?.spaces?.length)}
						>
							<AccordionSummary
								expandIcon={<ExpandMore />}
								aria-controls="panel1bh-content"
								id="panel1bh-header"
							>
								<Typography sx={{ width: "33%", flexShrink: 0 }}>
									{floor?.name || ""}
								</Typography>
								<Typography sx={{ color: "text.secondary" }}>
									{!Boolean(floor?.spaces?.length)
										? "No spaces found"
										: `${floor.spaces.length} space${
												Boolean(floor.spaces.length > 1) ? "s" : ""
										  }`}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Title title={`Floor Metadata` || ""} size="small" dense />
								<IAQMetadataTable
									metadata={floor?.metadata}
									size="small"
									location={{
										buildingId: building?.id,
										floorId: floor?.id,
									}}
									resource="floor"
								/>

								<List style={{ display: "flex" }}>
									<ListItem sx={{ width: "33%", flexShrink: 0 }}>
										<ListItemText primary="Spaces" />
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Devices"
											style={{ textAlign: "right" }}
										/>
									</ListItem>
								</List>
								<Divider />

								{/* //3 		Floor Spaces	 */}
								<List style={{ display: "flex", flexWrap: "wrap" }}>
									{orderBy(floor?.spaces, ["name"]).map((space: any) => {
										/* //- 		If less than 6 devices, show buttons else show menu	 */
										return (
											<CustomizedListItem
												key={space?.id}
												secondaryAction={
													space?.devices?.length <= 3 ? (
														orderBy(space?.devices, ["name"]).map(
															(device: any) => {
																return (
																	<ButtonGroup
																		variant="contained"
																		style={{
																			marginLeft: "1rem",
																			marginBottom: ".5rem",
																		}}
																	>
																		{" "}
																		<Button
																			variant="contained"
																			onClick={() =>
																				history.push(
																					`/iaq/${building?.id}/${device?.id}`
																				)
																			}
																			disabled={Boolean(!device?.id)}
																		>
																			{device?.name || ""}
																		</Button>
																		<Button
																			variant="outlined"
																			color="secondary"
																			onClick={() =>
																				history.push(
																					`/iaq/${building?.id}/${device?.id}?dialOnly`
																				)
																			}
																			disabled={Boolean(!device?.id)}
																		>
																			{`Dial Only` || ""}
																		</Button>
																	</ButtonGroup>
																);
															}
														)
													) : (
														<ButtonGroup variant="contained">
															<IAQDeviceMenu space={space} />
															<IAQDeviceMenu space={space} dialOnly />
														</ButtonGroup>
													)
												}
											>
												<ListItemIcon>
													<ViewCompact />
												</ListItemIcon>

												<ListItemText
													primary={space?.name || ""}
													secondary={
														<IAQMetadataMenu
															metadata={space?.metadata}
															location={{
																buildingId: building?.id,
																floorId: floor?.id,
																spaceId: space?.id,
															}}
															resource="space"
														/>
													}
												/>
											</CustomizedListItem>
										);
									})}
								</List>
							</AccordionDetails>
						</Accordion>
					);
				})}
			</Section>
		</div>
	);
}
