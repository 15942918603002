import { Button, Menu, MenuItem } from "@mui/material";
import { isEmpty } from "lodash";
import * as React from "react";
import { useHistory } from "react-router";
import { getDialRoute } from "./IAQBuildingTable";

const convertCamel = (str: string) => {
	if (typeof str !== "string") return "";

	return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
		return str.toUpperCase();
	});
};

interface IMetadata {
	avg: number;
	high: number;
	low: number;
	count: number;
}

interface IIAQMetadataMenuProps {
	metadata: { [key: string]: IMetadata };
	location: {
		buildingId?: string;
		floorId?: string;
		spaceId?: string;
	};
	resource: string;
}

export const IAQMetadataMenu = ({
	metadata,
	location,
	resource,
}: IIAQMetadataMenuProps) => {
	const history = useHistory();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuDevice = (key: string) => {
		let pathname = "/iaq/metadata/";
		if (location.buildingId) pathname += location.buildingId;
		if (location.floorId) pathname += `/${location.floorId}`;
		if (location.spaceId) pathname += `/${location.spaceId}`;

		setAnchorEl(null);
		history.push(
			getDialRoute({
				pathname,
				resource,
				component: key,
				type: "dialOnly",
			})
		);
	};

	return (
		<div>
			<Button
				id="basic-button"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				size="small"
				style={{ paddingLeft: 0 }}
			>
				{Boolean(!isEmpty(metadata)) ? `Metadata` : "No metadata found"}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{Object.keys(metadata).map((metaKey: any) => {
					return (
						<MenuItem key={metaKey} onClick={() => handleMenuDevice(metaKey)}>
							{convertCamel(metaKey)}
						</MenuItem>
					);
				})}
				<MenuItem key={"all"} onClick={() => handleMenuDevice("all")}>
					{convertCamel("all")}
				</MenuItem>
			</Menu>
		</div>
	);
};
