import * as React from "react";

// MUI
import { Divider, Typography } from "@material-ui/core";

// Charts
import {
	Line,
	LineChart,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

// Util
import { orderBy } from "lodash";
import moment from "moment";

// Components
import { GlassPaper } from "../GlassPaper/GlassPaper";

// Interfaces
import { IHistoryData } from "../../interfaces";
import { interpolateRange } from "../../util";

interface ITooltipPayload {
	color: string;
	dataKey: string;
	fill: string;
	formatter: any;
	name: string;
	payload: any;
	stroke: string;
	strokeWidth: number;
	type: any;
	unit: any;
	value: number;
}

interface ICustomTooltip {
	active: boolean;
	payload: ITooltipPayload[];
	label: string;
}

// Custom Tooltip
const CustomTooltip: any = ({ active, payload, label }: ICustomTooltip) => {
	if (active && payload && payload.length) {
		// Get label and convert to time if timestamp
		let label = payload[0].name;
		if (payload[0].payload?.timestamp) {
			label = moment(payload[0].payload?.timestamp).format(
				"MMMM Do YYYY, h:mm a"
			);
		}

		// Get value and round if available
		let value = payload[0].value;
		if (typeof value === "number") {
			value = Math.round(value);
		}

		return (
			<GlassPaper square style={{ padding: "1rem" }}>
				<Typography variant="body2" align="center">
					{label}
				</Typography>
				<Divider style={{ width: "100%" }} />
				<Typography variant="body1" align="center">
					{value}
				</Typography>
			</GlassPaper>
		);
	}

	return null;
};

// Convenience Functions
const getAverage = (array: IHistoryData["data"]) => {
	// If data comes through
	if (Boolean(array.length)) {
		let ave = array.reduce(
			(a, b) => {
				a.value = a.value + b.value;
				return a;
			},
			{ value: 0 }
		);

		return ave.value / array.length;
	} else return 0;
};

const everyNth = (arr: any[], nth: number) =>
	arr?.filter((e: any, i: any) => i % nth === nth - 1) || [];

interface IHistoryLineChart {
	historyData: null | IHistoryData;
	showAverage?: boolean;
	sampleNth?: number;
	height?: number;
	width?: number | string;
	margins?: {
		top?: number;
		right?: number;
		left?: number;
		bottom?: number;
	};
	showTooltip?: boolean;
	label?: string;
	type?: "step" | "monotoneX" | "natural" | undefined;
	additionalLines?: any[];
}

// Component
export const HistoryLineChart = ({
	historyData,
	showAverage = true,
	sampleNth = 10,
	height = 275,
	width = "90%",
	margins = {
		top: 5,
		right: 30,
		left: 0,
		bottom: 5,
	},
	showTooltip = true,
	label = "",
	type = "step",
	additionalLines = [],
}: IHistoryLineChart) => {
	// null if no data available
	if (!historyData || !historyData?.data?.length) return null;

	let max = "auto";

	const range = Math.round(
		interpolateRange(0, 100, 0, historyData.data.length, sampleNth)
	);

	// Handle Data Sample
	let data;
	if (Boolean(sampleNth)) {
		data = orderBy(everyNth(historyData.data, range), "timestamp");
	} else {
		data = orderBy(historyData.data, "timestamp");
	}

	// Handle Average Line
	let average = 0;
	if (showAverage) {
		average = getAverage(historyData.data);
	}

	// Handle Range Sliders

	// Render
	return (
		<>
			{/* Title */}
			{Boolean(label) && (
				<Typography
					variant="h6"
					style={{ textAlign: "center", paddingBottom: "1rem" }}
				>
					{label || historyData.name || ""}
				</Typography>
			)}

			{/* Chart Wrapper */}
			<ResponsiveContainer width={width} height={height}>
				<LineChart data={data} margin={margins}>
					{/* Axis Data */}
					<XAxis
						name="Time"
						dataKey="timestamp"
						type="number"
						domain={["dataMin", "dataMax"]}
						tickFormatter={(timestamp: number) =>
							moment(timestamp).format("ddd DD, hA")
						}
						label={{ fontSize: ".5rem" }}
						// padding={{ right: 20 }}
					/>
					<YAxis
						name="Value"
						dataKey="value"
						domain={[0, max]}
						interval="preserveStartEnd"
					/>

					{/* Data */}
					<Line
						name="Value"
						dataKey="value"
						type={type}
						stroke="#8884d8"
						dot={false}
						label={false}
					/>

					{/* Tooltip */}
					{Boolean(showTooltip) && <Tooltip content={<CustomTooltip />} />}

					{/* Average Reference LIne */}
					{showAverage && (
						<ReferenceLine
							y={average}
							stroke="green"
							label={
								typeof average === "number"
									? `Ave: ${Number(average).toFixed(2)}`
									: ""
							}
						/>
					)}
				</LineChart>
			</ResponsiveContainer>
		</>
	);
};
