import { createSlice } from "@reduxjs/toolkit";

interface IAppStateSlice {
	selectedClient: { id: string; name: string };
	selectedBuilding: {
		id: string;
		name: string;
		clientId: string;
		clientName: string;
		siteId: string;
		siteName: string;
	};
	selectedFloor: {
		id: string;
		name: string;
		clientId: string;
		clientName: string;
		siteId: string;
		siteName: string;
		buildingId: string;
		buildingName: string;
	};
	selectedSpace: { id: string; name: string };
	error: string;
}

export const appStateSlice = createSlice({
	name: "appState",
	initialState: {
		selectedClient: { id: "", name: "" },
		selectedBuilding: {
			id: "",
			name: "",
			clientId: "",
			clientName: "",
			siteId: "",
			siteName: "",
		},
		selectedFloor: {
			id: "",
			name: "",
			clientId: "",
			clientName: "",
			siteId: "",
			siteName: "",
			buildingId: "",
			buildingName: "",
		},
		selectedSpace: { id: "", name: "" },
	} as IAppStateSlice,
	reducers: {
		selectClient: (
			state: any,
			action: { payload: { id: string; name: string } }
		) => {
			state.selectedClient = {
				id: action.payload.id,
				name: action.payload.name,
			};
		},
		selectBuilding: (
			state: any,
			action: {
				payload: {
					clientId: string;
					clientName: string;
					siteId: string;
					siteName: string;
					id: string;
					name: string;
				};
			}
		) => {
			state.selectedBuilding = {
				clientId: action.payload.clientId,
				clientName: action.payload.clientName,
				siteId: action.payload.siteId,
				siteName: action.payload.siteName,
				id: action.payload.id,
				name: action.payload.name,
			};
		},
		selectFloor: (
			state: any,
			action: {
				payload: {
					clientId: string;
					clientName: string;
					siteId: string;
					siteName: string;
					buildingId: string;
					buildingName: string;
					id: string;
					name: string;
				};
			}
		) => {
			state.selectedFloor = {
				clientId: action.payload.clientId,
				clientName: action.payload.clientName,
				siteId: action.payload.siteId,
				siteName: action.payload.siteName,
				buildingId: action.payload.buildingId,
				buildingName: action.payload.buildingName,
				id: action.payload.id,
				name: action.payload.name,
			};
		},
		selectSpace: (
			state: any,
			action: {
				payload: {
					clientId: string;
					buildingId: string;
					id: string;
					name: string;
				};
			}
		) => {
			state.selectedSpace = {
				clientId: action.payload.clientId,
				buildingId: action.payload.buildingId,
				id: action.payload.id,
				name: action.payload.name,
			};
		},
	},
});

export const { selectClient, selectBuilding, selectFloor, selectSpace } =
	appStateSlice.actions;

export default appStateSlice.reducer;
