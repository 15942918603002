import * as React from "react";

// Util
import { find } from "lodash";
import { Grid } from "@material-ui/core";
import { GlassDial } from "../../GlassDial/GlassDial";

// Interfaces
interface IDensityDialProps {
	deviceData: any;
}

// Render
export const DensityDial: React.FC<IDensityDialProps> = ({ deviceData }) => {
	const points = deviceData?.points;

	// TODO: Find alternative return value when no points exist
	if (!points || !Boolean(points?.length)) return null;

	// Parse out components
	const capacity = find(points, ["virtualDeviceSlot", "capacity"]); // number: capacity
	const currentCount = find(points, ["virtualDeviceSlot", "currentCount"]); // number: current capacity

	return (
		<Grid container style={{ padding: "1rem 0" }}>
			<Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
				<GlassDial
					id={currentCount.id}
					value={currentCount.data.value}
					valueSuffix={` / ${capacity.data.value}`}
					label={"Occupancy"}
					size={250}
					fontSize={"4rem"}
					color={`#${currentCount.color}`}
					max={capacity.data.value}
				/>
			</Grid>
		</Grid>
	);
};
