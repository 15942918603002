import * as React from "react";

// MUI
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";

// Icons
import { ReactComponent as PM25 } from "../../../../static/svg/pm25.svg";
import { ReactComponent as CO2 } from "../../../../static/svg/c02.svg";
import { ReactComponent as Humidity } from "../../../../static/svg/humidity.svg";
import { ReactComponent as Tvocs } from "../../../../static/svg/tvocs.svg";
import { ReactComponent as Temp } from "../../../../static/svg/climate.svg";

// Util
import { find } from "lodash";

// Components
import { IAQComponentCard } from "./IAQComponentCard";

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		componentContainer: {
			display: "flex",
			flexDirection: "column",
		},
		componentWrapper: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
	})
);

interface IIAQComponentSheetProps {
	iaqPoints: any;
	size?: "small";
}

export const IAQComponentSheet: React.FC<IIAQComponentSheetProps> = ({
	iaqPoints,
	size,
}) => {
	// Styles
	const classes = useStyles();

	// Components
	const co2 = find(iaqPoints, ["virtualDeviceSlot", "co2"]);
	const humidity = find(iaqPoints, ["virtualDeviceSlot", "humidity"]);
	const pm25 = find(iaqPoints, ["virtualDeviceSlot", "pm25"]);
	const voc = find(iaqPoints, ["virtualDeviceSlot", "voc"]);
	const temp = find(iaqPoints, ["virtualDeviceSlot", "tempDisplay"]);

	// If no points, hide
	if (!iaqPoints || !Boolean(iaqPoints?.length)) return null;

	let height = size === "small" ? "1rem" : "1.5rem";
	let margin = size === "small" ? 0 : "0 .5rem";
	let padding = size === "small" ? 0 : "1.5rem 4rem";

	// Render
	return (
		<div className={classes.componentContainer} style={{ padding }}>
			{/* CO2 */}
			{Boolean(co2) && (
				<Grid item xs={12} className={classes.componentWrapper}>
					{
						<IAQComponentCard
							name={"CO2"}
							iaqComponent={co2}
							icon={<CO2 height={height} style={{ margin }} />}
						/>
					}
				</Grid>
			)}

			{/* Humidity */}
			{Boolean(humidity) && (
				<Grid item xs={12} className={classes.componentWrapper}>
					{
						<IAQComponentCard
							name={"Humidity"}
							iaqComponent={humidity}
							icon={<Humidity height={height} style={{ margin }} />}
						/>
					}
				</Grid>
			)}

			{/* PM25 */}
			{Boolean(pm25) && (
				<Grid item xs={12} className={classes.componentWrapper}>
					{
						<IAQComponentCard
							name="PM25"
							iaqComponent={pm25}
							icon={<PM25 height={height} style={{ margin }} />}
						/>
					}
				</Grid>
			)}

			{/* Tvocs */}
			{Boolean(voc) && (
				<Grid item xs={12} className={classes.componentWrapper}>
					{
						<IAQComponentCard
							name="VOCs"
							iaqComponent={voc}
							icon={<Tvocs height={height} style={{ margin }} />}
						/>
					}
				</Grid>
			)}

			{/* Temp */}
			{Boolean(temp) && (
				<Grid item xs={12} className={classes.componentWrapper}>
					{
						<IAQComponentCard
							name="Temp"
							iaqComponent={temp}
							icon={<Temp height={height} style={{ margin }} />}
						/>
					}
				</Grid>
			)}
		</div>
	);
};
