import { Button, Menu, MenuItem } from "@mui/material";
import { orderBy } from "lodash";
import * as React from "react";
import { useHistory, useParams } from "react-router";

interface IIAQDeviceMenuProps {
	space: any;
	dialOnly?: boolean;
}

export const IAQDeviceMenu = ({ space, dialOnly }: IIAQDeviceMenuProps) => {
	const history = useHistory();
	const params: any = useParams();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuDevice = (id: string) => {
		setAnchorEl(null);
		history.push({
			pathname: `/iaq/${params?.buildingId}/${id}`,
			search: dialOnly ? "dialOnly" : "",
		});
	};

	return (
		<div>
			<Button
				id="basic-button"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				variant={dialOnly ? "outlined" : "contained"}
			>
				{Boolean(space?.devices?.length && !dialOnly)
					? `${space.devices.length} Devices`
					: Boolean(space?.devices?.length && dialOnly)
					? "Dial Only"
					: "No devices found"}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{orderBy(space?.devices, ["name"]).map((device: any) => {
					return (
						<MenuItem
							key={device?.id}
							onClick={() => handleMenuDevice(device.id)}
							disabled={!device?.id}
						>
							{device.name}
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
};
