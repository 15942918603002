import React from "react";

// Route
import { Route, Redirect } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

// Types
import { RootState } from "../app/store";

const PrivateRoute = ({
	children,
	exact,
	...rest
}: {
	path: string;
	exact?: boolean;
	children: any;
}) => {
	// Auth Selector
	const auth = useSelector((state: RootState) => state.firebase.auth);

	return (
		<Route
			exact={Boolean(exact)}
			{...rest}
			render={({ location }) =>
				Boolean(auth.isLoaded && !auth.isEmpty) ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
};

export default PrivateRoute;
