import * as React from "react";

// MUI
import { makeStyles, Theme } from "@material-ui/core";

// Colors
import { lightBlue, lightGreen } from "@material-ui/core/colors";

// Components
import { Dial } from "./Dial";
import { IVirtualThermostatDevice } from "../../../../interfaces";

// Util
import { find } from "lodash";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	dialWrapper: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	activeMode: {
		background: lightBlue[500],
	},
	activeStatus: {
		background: lightGreen[500],
	},
}));

interface IDeviceData {
	deviceData: IVirtualThermostatDevice;
}

const min = 65;
const max = 90;

export const TemperatureDial = ({ deviceData }: IDeviceData) => {
	const classes = useStyles();

	const [windowSize, setWindowSize] = React.useState<any>();
	const [dynamicSize, setDynamicSize] = React.useState(125);
	const parentRef = React.useRef<HTMLDivElement>(null);

	React.useLayoutEffect(() => {
		function updateSize() {
			setWindowSize([window.innerWidth, window.innerHeight]);
		}

		window.addEventListener("resize", updateSize);
		return () => window.removeEventListener("resize", updateSize);
	}, []);

	React.useEffect(() => {
		if (parentRef?.current) {
			let parentHeight = parentRef.current.offsetHeight;
			let parentWidth = parentRef.current.offsetWidth;

			setDynamicSize(Math.min(Math.min(parentHeight, parentWidth) / 2, 250));
		}
	}, [windowSize, parentRef]);

	// Demo
	const [status] = React.useState("unoccupied");

	// Return null if no device data found
	if (!deviceData) return null;

	//-		 			Device Points 				//
	//----------------------------------------------//
	//1 		Temperature Value Point				//
	const tempValuePoint = find(deviceData?.points, [
		"virtualDeviceSlot",
		"tempDisplay",
	]);

	//2 		Temperature Status Point			//
	const tempStatusPoint = find(deviceData?.points, [
		"virtualDeviceSlot",
		"status",
	]);

	//.				NOTE ABOUT SETPOINTS			//
	//. -------------------------------------------	//
	//.		The COOLING setpoint will be higher 	//
	//. 	than the HEATING. Think of these points	//
	//.		as temps at which to engage heat / cool	//

	//3 		Cooling Setpoint Point				//
	const coolingSetpointPoint = find(deviceData?.points, [
		"virtualDeviceSlot",
		"tempDecrease",
	]);

	//4 		Heating Setpoint Point				//
	const heatingSetpointPoint = find(deviceData?.points, [
		"virtualDeviceSlot",
		"tempIncrease",
	]);
	const singleSetpointPoint = find(deviceData?.points, [
		"virtualDeviceSlot",
		"setpoint",
	]);

	//-		 			Point Values 				//
	//----------------------------------------------//
	//1 		Temperature Value					//
	const tempValue =
		typeof tempValuePoint?.data?.value === "number"
			? Math.round(tempValuePoint?.data?.value)
			: 0;

	//2 		Temperature Status					//
	const tempStatus =
		typeof tempStatusPoint?.data?.displayString === "string"
			? tempStatusPoint.data.displayString.toLowerCase()
			: "";

	//3 		Cooling Setpoint					//
	const coolingSetpoint =
		typeof coolingSetpointPoint?.data?.value === "number"
			? Math.round(coolingSetpointPoint?.data?.value)
			: 0;

	//4 		Heating Setpoint					//
	const heatingSetpoint =
		typeof heatingSetpointPoint?.data?.value === "number"
			? Math.round(heatingSetpointPoint?.data?.value)
			: 0;

	//5			Single Setpoint						//
	const singleSetpoint =
		typeof singleSetpointPoint?.data?.value === "number"
			? Math.round(singleSetpointPoint?.data?.value)
			: undefined;

	return (
		<div
			style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
		>
			<div className={classes.dialWrapper}>
				<Dial
					variant={singleSetpointPoint ? "single" : "multi"}
					size={dynamicSize}
					min={min}
					max={max}
					numTicks={Number(max - min)}
					degrees={180}
					displayValue={tempValue}
					// Multi / Single SP Value
					setpointValue={singleSetpoint}
					setpointValues={{ cool: coolingSetpoint, heat: heatingSetpoint }}
					showLabels={true}
					modeLabel={tempStatus}
					disabled={status === "occupied"}
					// handleChange={handleSetpointChange}
				/>
			</div>
		</div>
	);
};
