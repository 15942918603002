import React from "react";

// MUI
import { Divider, Typography, useMediaQuery } from "@material-ui/core";

// Styles
import {
	Theme,
	createStyles,
	makeStyles,
	useTheme,
} from "@material-ui/core/styles";

// Util
import { isEmpty } from "lodash";

// Components
import { GlassDial } from "../GlassDial/GlassDial";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			background: "transparent",
			padding: "1rem",
		},
		wrapper: {
			padding: "1rem",
		},
		refreshWrapper: {
			width: "100%",
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
	})
);

interface IMetadataDial {
	title?: string;
	metadata: {
		high: string;
		avg: string;
		low: string;
		count: string;
	};
	appendToValue?: string;
	colorByValue?: boolean;
	min?: number;
	max?: number;
	size?: number;
}

export const MetadataDial: React.FC<IMetadataDial> = ({
	metadata,
	title,
	appendToValue,
	colorByValue = false,
	min,
	max,
	size,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	// Mobile
	React.useEffect(() => {
		//
	}, [mobile]);

	// If no metadata, return null;
	// TODO: Build better alternative to null
	if (!metadata || isEmpty(metadata)) {
		return null;
	}

	let avg = Number.isNaN(Number(metadata.avg)) ? -1 : Number(metadata.avg);
	// let high = Number.isNaN(Number(metadata.high)) ? -1 : Number(metadata.high);
	// let low = Number.isNaN(Number(metadata.low)) ? -1 : Number(metadata.low);
	let count = Number.isNaN(Number(metadata.count))
		? -1
		: Number(metadata.count);

	return (
		<div>
			<div className={classes.container}>
				<div className={classes.wrapper}>
					{Boolean(title) && (
						<>
							<div className={classes.refreshWrapper}>
								<div style={{ display: "flex", alignItems: "center" }}>
									<Typography variant="body1">{title}</Typography>
								</div>
							</div>

							<Divider style={{ margin: "1rem 0" }} />
						</>
					)}

					<div
						style={{ display: "flex", width: "100%", justifyContent: "center" }}
					>
						<GlassDial
							id={avg}
							value={avg}
							appendToValue={appendToValue}
							label={`${count ? count : "unknown"} devices`}
							size={size ? size : 150}
							colorByValue={colorByValue}
							min={min}
							max={max}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
