import React from "react";

// MUI
import {
	Button,
	Dialog,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Grid,
	ButtonGroup,
	LinearProgress,
	Collapse,
	FormControl,
	FormLabel,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";

// Lab
import { Skeleton } from "@material-ui/lab";

// Pickers
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

// Styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// Firebase
import { functions } from "../../firebase/firebase";

// Icons
import { Close } from "@material-ui/icons";

// Util
import clsx from "clsx";
import { find, isEmpty } from "lodash";
import MomentUtils from "@date-io/moment";

// Components
import { HistoryLineChart } from "./HistoryLineChart";

// Interfaces
import { IDevicePoint, IHistoryData } from "../../interfaces";

// Hooks
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

const fetchHistory = functions.httpsCallable("fetchHistory");

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			position: "relative",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
		wrapper: {
			padding: "1rem",
			[theme.breakpoints.down("sm")]: {
				padding: "1rem 1rem 1rem 0",
			},
		},
		controlContainer: {
			borderLeft: `1px solid ${theme.palette.text.disabled}`,
			paddingLeft: "2rem",
			[theme.breakpoints.down("sm")]: {
				borderLeft: "none",
				padding: 0,
			},
		},
		controlWrapper: {
			padding: "1rem 0",
			[theme.breakpoints.down("sm")]: {
				padding: ".5rem 1rem",
			},
		},
		formControl: {
			margin: theme.spacing(3),
		},
	})
);

const subtractDays = (days: number) => 1000 * 60 * 60 * 24 * days;

// Interfaces
interface IHistoryDataDialog {
	open: boolean;
	historyData: null | IHistoryData;
	clearHistoryData: () => void;
	devicePoints: IDevicePoint[];
}

export const HistoryDataDialog = ({
	open,
	historyData,
	clearHistoryData,
	devicePoints,
}: IHistoryDataDialog) => {
	const classes = useStyles();
	const snackbar = useSnackbar();

	// Redux
	const api = useSelector((state: any) => state.firebase.profile.api);
	const history = useSelector((state: any) => state.history.data);

	// Timeline Graph Options
	const [granularity, setGranularity] = React.useState(3);
	const [type, setType]: any = React.useState("step");
	const [showTooltip, setShowTooltip]: any = React.useState(true);
	const [additionalGraphs, setAdditionalGraphs]: any = React.useState([]);

	// Handle Timeline Data
	const [loadingData, setLoadingData] = React.useState(false);
	const [timelineData, setTimelineData]: any = React.useState({
		day: historyData,
		week: {},
		month: {},
		custom: {},
	});
	const [timelineDataSelector, setTimelineDataSelector]: any =
		React.useState("day");

	// Custom Timeline
	const [timelineMode, setTimelineMode]: any = React.useState("normal");
	const [date, setDate] = React.useState({
		start: new Date(),
		end: new Date(),
	});

	const handleChangeDate = (data: any, range: any) => {
		setDate({ ...date, [range]: data.toDate() });
	};

	const handleSetTimeline = (variant: string) => {
		setTimelineMode("normal");
		setTimelineDataSelector(variant);
	};

	// Additional Points
	const handleChangePoint = (id: string) => {
		const existingPoint = find(additionalGraphs, ["id", id]);

		if (Boolean(existingPoint)) {
			setAdditionalGraphs(
				additionalGraphs.filter((graph: any) => graph.pointId !== id)
			);
		} else {
			setAdditionalGraphs([...additionalGraphs, history[id]]);
		}
	};

	// . 			HISTORY FETCH HANDLER							//
	const handleFetchHistory = ({ start, end, type }: any) => {
		setLoadingData(true);
		fetchHistory({
			api,
			route: `/history/point/${historyData?.id}`,
			start: type === "custom" ? new Date(date.start).getTime() : start,
			end: end ? new Date(date.end).getTime() : Date.now(),
			zoneOffset: new Date().getTimezoneOffset() / 60,
		})
			.then((res: any) => {
				if (res && res?.data) {
					setTimelineData({ ...timelineData, [type]: res.data });
					setLoadingData(false);
				}
			})
			.catch((e: any) => {
				snackbar.enqueueSnackbar(
					`History fetch failed. Try a broader range between the Start Date and End Date.`,
					{
						variant: "error",
						autoHideDuration: 5000,
					}
				);
				setLoadingData(false);
			});
	};

	// . 			HANDLE WEEK / MONTH PRESET FETCHES				//
	React.useEffect(() => {
		// # 		If data already exists, ignore					//
		if (
			(timelineDataSelector === "day" && !isEmpty(timelineData.day)) ||
			(timelineDataSelector === "week" && !isEmpty(timelineData.week)) ||
			(timelineDataSelector === "month" && !isEmpty(timelineData.month))
		) {
		} else if (historyData?.id) {
			// 1 		Handle intial load							//
			if (historyData?.data && isEmpty(timelineData.day)) {
				setTimelineData({ ...timelineData, day: historyData });
			}

			// 2 		Handle start range							//
			let subtract =
				timelineDataSelector === "week"
					? 7
					: timelineDataSelector === "month"
					? 30
					: 1;
			let startRange = Date.now() - subtractDays(subtract);

			// 3 		Fetch data									//
			handleFetchHistory({
				start: startRange,
				type: timelineDataSelector,
			});
		}

		// eslint-disable-next-line
	}, [timelineDataSelector, historyData]);

	// Cleanup
	const handleClose = () => {
		setType("step");
		setTimelineDataSelector("day");
		setGranularity(3);
		setTimelineData({ day: {}, week: {}, month: {}, custom: {} });
		setTimelineMode("normal");
		setDate({ start: new Date(), end: new Date() });
		setAdditionalGraphs([]);
		clearHistoryData();
	};

	if (!Boolean(historyData)) return null;

	return (
		<div>
			<Dialog fullScreen open={open} onClose={handleClose}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<Close />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{historyData?.name}
						</Typography>
					</Toolbar>
				</AppBar>

				{Boolean(loadingData) && <LinearProgress />}

				<Grid container style={{ padding: "2rem 0" }}>
					{/* History Graph */}
					<Grid
						item
						xs={12}
						sm={6}
						style={{ justifyContent: "center" }}
						className={classes.wrapper}
					>
						{loadingData ? (
							<LinearProgress style={{ width: "100%" }} />
						) : (
							<>
								<HistoryLineChart
									label={historyData?.name}
									historyData={
										Boolean(
											timelineMode === "custom" &&
												!isEmpty(timelineData["custom"])
										)
											? timelineData["custom"]
											: timelineData[timelineDataSelector]
									}
									height={200}
									width="100%"
									sampleNth={granularity}
									showTooltip={showTooltip}
									type={type}
								/>
							</>
						)}

						{Boolean(additionalGraphs.length) && loadingData ? (
							<Skeleton width="100%" height={"100%"} style={{ padding: 0 }} />
						) : (
							additionalGraphs.map((graph: any) => (
								<HistoryLineChart
									key={graph.id}
									label={graph?.name}
									historyData={graph}
									height={200}
									width="100%"
									sampleNth={granularity}
									showTooltip={showTooltip}
									type={type}
								/>
							))
						)}
					</Grid>

					{/* History Graph Controls */}
					<Grid
						item
						xs={12}
						sm={6}
						className={clsx(classes.wrapper, classes.controlContainer)}
					>
						<div className={classes.controlWrapper}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Graph Options</FormLabel>
								<FormControlLabel
									control={
										<Checkbox
											checked={showTooltip}
											onChange={() => setShowTooltip(!showTooltip)}
											name="Show Tooltip"
										/>
									}
									label="Show Tooltip"
								/>
							</FormControl>
						</div>

						<div className={classes.controlWrapper}>
							<FormControl component="fieldset">
								<FormLabel
									component="legend"
									style={{ paddingBottom: ".5rem" }}
								>
									Granularity
								</FormLabel>
								<ButtonGroup>
									<Button
										color="primary"
										onClick={() => setGranularity(5)}
										variant={granularity === 5 ? "contained" : "outlined"}
									>
										Coarse
									</Button>
									<Button
										color="primary"
										onClick={() => setGranularity(3)}
										variant={granularity === 3 ? "contained" : "outlined"}
									>
										Medium
									</Button>
									<Button
										color="primary"
										onClick={() => setGranularity(1)}
										variant={granularity === 1 ? "contained" : "outlined"}
									>
										Fine
									</Button>
									<Button
										color="primary"
										onClick={() => setGranularity(0)}
										variant={granularity === 0 ? "contained" : "outlined"}
									>
										All
									</Button>
								</ButtonGroup>
							</FormControl>
						</div>
						<div className={classes.controlWrapper}>
							<FormControl component="fieldset">
								<FormLabel
									component="legend"
									style={{ paddingBottom: ".5rem" }}
								>
									Line Type
								</FormLabel>
								<ButtonGroup>
									<Button
										color="primary"
										onClick={() => setType("linear")}
										variant={type === "linear" ? "contained" : "outlined"}
									>
										Linear
									</Button>
									<Button
										color="primary"
										onClick={() => setType("step")}
										variant={type === "step" ? "contained" : "outlined"}
									>
										Step
									</Button>
									<Button
										color="primary"
										onClick={() => setType("monotoneX")}
										variant={type === "monotoneX" ? "contained" : "outlined"}
									>
										Curve
									</Button>
								</ButtonGroup>
							</FormControl>
						</div>

						<div className={classes.controlWrapper}>
							<FormControl component="fieldset">
								<FormLabel
									component="legend"
									style={{ paddingBottom: ".5rem" }}
								>
									Time Range
								</FormLabel>

								<ButtonGroup disabled={Boolean(loadingData)}>
									<Button
										color="primary"
										onClick={() => handleSetTimeline("day")}
										variant={
											timelineMode === "normal" &&
											timelineDataSelector === "day"
												? "contained"
												: "outlined"
										}
									>
										Day
									</Button>
									<Button
										color="primary"
										onClick={() => handleSetTimeline("week")}
										variant={
											timelineMode === "normal" &&
											timelineDataSelector === "week"
												? "contained"
												: "outlined"
										}
									>
										Week
									</Button>
									<Button
										color="primary"
										onClick={() => handleSetTimeline("month")}
										variant={
											timelineMode === "normal" &&
											timelineDataSelector === "month"
												? "contained"
												: "outlined"
										}
									>
										Month
									</Button>
									<Button
										color="primary"
										onClick={() => setTimelineMode("custom")}
										variant={
											timelineMode === "custom" ? "contained" : "outlined"
										}
									>
										Custom
									</Button>

									{/* Custom Controls */}
								</ButtonGroup>
							</FormControl>

							<Collapse in={timelineMode === "custom"}>
								<div style={{ padding: "1rem 0", display: "flex" }}>
									{/* Start Date */}
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<>
											<DatePicker
												autoOk
												disableFuture
												inputVariant="outlined"
												label="Start Date"
												openTo="date"
												value={date.start}
												onChange={(date: any) =>
													handleChangeDate(date, "start")
												}
											/>
										</>
									</MuiPickersUtilsProvider>

									{/* End Date */}
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<>
											<DatePicker
												disableFuture
												showTodayButton
												inputVariant="outlined"
												label="End Date"
												openTo="date"
												minDate={date.start}
												value={date.end}
												onChange={(date: any) => handleChangeDate(date, "end")}
											/>
										</>
									</MuiPickersUtilsProvider>

									<Button
										disabled={loadingData}
										variant="contained"
										onClick={() =>
											handleFetchHistory({
												start: date.start,
												end: date.end,
												type: "custom",
											})
										}
										color="primary"
									>
										Apply
									</Button>
								</div>
							</Collapse>
						</div>

						{/* Additional Points of interest */}
						{/* //! 			TURNING OFF ADDITIONAL POINTS UNTIL DEBUG 				 */}
						{Boolean(false) && (
							<div
								className={classes.controlWrapper}
								style={{ marginTop: "1rem" }}
							>
								<FormControl component="fieldset">
									<FormLabel
										component="legend"
										style={{ paddingBottom: ".5rem" }}
									>
										Additional Points on this Device
									</FormLabel>

									<div style={{ display: "flex", flexWrap: "wrap" }}>
										{devicePoints.map((point: any) => (
											<div key={point.id} style={{ margin: "0 .5rem .5rem 0" }}>
												{Boolean(history[point.id]) && (
													<Button
														disabled={historyData?.id === point.id}
														variant={
															Boolean(find(additionalGraphs, ["id", point.id]))
																? "contained"
																: "outlined"
														}
														color={
															Boolean(find(additionalGraphs, ["id", point.id]))
																? "secondary"
																: "default"
														}
														onClick={() => handleChangePoint(point.id)}
													>
														{point.name}
													</Button>
												)}
											</div>
										))}
									</div>
								</FormControl>
							</div>
						)}
					</Grid>
				</Grid>
			</Dialog>
		</div>
	);
};
