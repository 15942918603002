import * as React from "react";

// MUI
import { Divider, Typography } from "@material-ui/core";

// #		Component takes either a title as a string, or children			//
// #			- Primary use is for the GlassCard to apply consistent		//
// #			styling across all card components							//
export const GlassPaperTitle = ({
	title,
	children,
}: {
	title?: string;
	children?: any;
}) => {
	return (
		<>
			{Boolean(children) ? (
				// 1 	If children, render children					//
				children
			) : title ? (
				// 2 	If title is provided, render title 				//
				<Typography variant="body2" paragraph>
					{title}
				</Typography>
			) : // 3 	If neither, render nothing						//
			null}

			{Boolean(title || children) && (
				// 4	If either children or title, render divider		//
				<Divider style={{ marginBottom: "2rem" }} />
			)}
		</>
	);
};
