import * as React from "react";

// Redux
import { useAppSelector } from "../../../../app/hooks";

// Interfaces
import { IPoint } from "../../../../interfaces";
import { RootState } from "../../../../app/store";

// MUI
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";

// Components
import { TemperatureDial } from "../TemperatureDial/TemperatureDial";
import { TemperatureControls } from "..";
import usePollDevice from "../../../../hooks/useDeviceInterval";
import { GlassCard } from "../../../GlassCard/GlassCard";

interface IThermostatCardProps {
	id: string;
	name: string;
	points: IPoint | any[];
	virtualProfile: string;
}

interface IThermostatCardContainerProps {
	thermostatDevice: IThermostatCardProps;
}

export const ThermostatCard: React.FC<IThermostatCardContainerProps> = ({
	thermostatDevice,
}) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.only("xs"));

	// Building data
	const deviceStore = useAppSelector((state: RootState) => state.devices); //devices fetched and stored on selection
	const deviceData = deviceStore.data?.[thermostatDevice.id];

	const [pollData] = usePollDevice({
		deviceData,
		route: `device/${thermostatDevice.id}`,
	});

	const activeDevice = pollData || deviceData;

	// If no device data or the device has no points, return null;
	if (Boolean(!deviceData || !deviceData?.points?.length)) {
		return null;
	}

	if (!deviceData) return null;

	return (
		<GlassCard title={activeDevice.name}>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<TemperatureDial deviceData={pollData || deviceData} />
				</Grid>
				{mobile && (
					<span style={{ width: "100%", display: "flex", height: "20px" }} />
				)}
				<Grid item xs={12} sm={6}>
					<TemperatureControls deviceData={deviceData} />
				</Grid>
			</Grid>
		</GlassCard>
	);
};
