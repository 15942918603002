import firebase from "firebase";

import "firebase/auth/dist/index.cjs";
import "firebase/database/dist/index.cjs";

let config;
if (
    process.env.NODE_ENV === "development" ||
    window.location.host.split(".")[0] === "staging"
) {
    config = {
        apiKey: "AIzaSyCwzOQwEhsdE2bAJJn3mq_9G7hjCH90Ss4",
        authDomain: "tapa-production.firebaseapp.com",
        databaseURL: "https://tapa-production.firebaseio.com",
        projectId: "tapa-production",
        storageBucket: "tapa-production.appspot.com",
        messagingSenderId: "619069624986",
        appId: "1:619069624986:web:0041abe8e24a2436d80523",
    };
} else {
    config = {
        apiKey: "AIzaSyCwzOQwEhsdE2bAJJn3mq_9G7hjCH90Ss4",
        authDomain: "tapa-production.firebaseapp.com",
        databaseURL: "https://tapa-production.firebaseio.com",
        projectId: "tapa-production",
        storageBucket: "tapa-production.appspot.com",
        messagingSenderId: "619069624986",
        appId: "1:619069624986:web:0041abe8e24a2436d80523",
    };
}

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

//Use Admin SDK
const auth = firebase.auth();
const db = firebase.database();
const functions = firebase.functions();
const store = firebase.firestore();
const storage = firebase.storage();

export { auth, db, functions, storage, store };
