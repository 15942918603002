import React, { useCallback, useEffect } from "react";

// MUI
import {
	Avatar,
	Button,
	Chip,
	Collapse,
	Container,
	Dialog,
	DialogTitle,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	LinearProgress,
	Menu,
	MenuItem,
	Typography,
	Theme,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

// Styles
import { createStyles, makeStyles } from "@material-ui/styles";

// Icons
import { Clear, Info, Refresh } from "@material-ui/icons";

// Colors
import { deepOrange, lightBlue } from "@material-ui/core/colors";

// Redux hooks
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";

// Redux actions
import { fetchBuilding } from "../../features/buildings/buildingsSlice";
import {
	selectBuilding,
	selectFloor,
} from "../../features/appState/appStateSlice";

// Firebase
import { useFirebaseConnect } from "react-redux-firebase";

// Util
import { find, isEmpty, sortBy } from "lodash";

// Components
import { FavoriteListItem } from "../../components/FavoriteListItem/FavoriteListItem";
import { GlassPaper } from "../../components/GlassPaper/GlassPaper";
import { MetadataDial } from "../../components/MetadataDial/MetadataDial";
import { Weather } from "../../components/Weather/Weather";

// Interfaces
import { RootState } from "../../app/store";

export interface IFavoriteData {
	key?: string; //db key
	name: string;
	buildingId: string;
	floorId: string;
	spaceId: string;
	deviceId: string;
	type: string;
}

interface ISpacesProps {}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: "1rem",
		},
		wrapper: {
			padding: "1rem 0",
		},
		header: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
		spacesContainer: {
			marginRight: "1rem",
			marginBottom: "1rem",
			width: "100%",
		},
		primaryTextTitle: {
			fontSize: "1.5rem",
			fontWeight: 700,
		},
		// State
		active: {
			backgroundColor: lightBlue[500],
			[`&:hover`]: {
				backgroundColor: lightBlue[700],
			},
		},
		transparent: {
			background: "transparent",
			boxShadow: "none",
		},
		blur: {
			backdropFilter: "blur(7px)",
		},
	})
);

export const Spaces: React.FC<ISpacesProps> = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	// Store
	const api = useAppSelector((state: RootState) => state.firebase.profile.api);
	const clientsStore = useAppSelector((state: RootState) => state.clients); //clients from init fetch
	const appState = useAppSelector((state: RootState) => state.appState); //selections stored in redux
	const { selectedBuilding, selectedFloor } = appState;
	const buildingStore = useAppSelector((state: RootState) => state.buildings); //buildings fetched and stored on selection

	// Favorites from DB
	const uid = useAppSelector((state: any) => state.firebase?.auth?.uid);
	const control = useAppSelector(
		(state: any) => state.firebase.ordered?.users?.[uid]?.control
	);
	useFirebaseConnect([`users/${uid}/control`]);
	const favoriteSpacesFetch = Array.isArray(control)
		? find(control, ["key", "favoriteSpaces"]) || { key: "", value: {} }
		: { key: "", value: {} };

	// State
	const [buildings, setBuildings]: any = React.useState([]);
	const [floors, setFloors]: any = React.useState([]);
	const [dialog, setDialog] = React.useState({
		building: false,
		floor: false,
		space: false,
	});

	// Menu Anchors
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [floorAnchorEl, setFloorAnchorEl] = React.useState<null | HTMLElement>(
		null
	);

	// Compile list of buildings (should be called once)
	const compileBuildings: any = useCallback(() => {
		let buildingList: any = [];

		// Go through clients, sites, and push buildings to buildingList
		Object.values(clientsStore.data)?.forEach((client: any) => {
			client.sites?.forEach((site: any) => {
				site.buildings?.forEach((building: any) => {
					buildingList.push({
						id: building.id,
						name: building.name,
						clientId: client.id,
						clientName: client.name,
						siteId: site.id,
						siteName: site.name,
					});
				});
			});
		});

		// Set buildings in list
		setBuildings(buildingList);
		// eslint-disable-next-line
	}, [clientsStore.data, buildings]);

	// Handlers (Selectors)
	const handleSelectBuilding = (building: any) => {
		// Set selected building in redux appState
		dispatch(selectBuilding(building));

		// If there's no building in the store, fetch and store it
		if (!Boolean(buildingStore.data[building.id])) {
			// Fetch building, store it, and clear floor data
			// useEffect hook below will detect the building change and automatically select a floor
			setFloors([]);
			dispatch(fetchBuilding({ api, id: building.id }));
			dispatch(
				selectFloor({
					clientId: "",
					clientName: "",
					siteId: "",
					siteName: "",
					buildingId: "",
					buildingName: "",
					id: "",
					name: "",
				})
			);
		}

		// If there are floors, set the floor to the newly selected building's first floor
		if (
			floors.length &&
			Boolean(buildingStore.data[building.id]?.floors?.length)
		) {
			dispatch(
				selectFloor({
					...selectedBuilding,
					buildingName: selectedBuilding.name,
					buildingId: selectedBuilding.id,
					name: floors[0].name,
					id: floors[0].id,
				})
			);
		}

		// Unanchor menu
		setAnchorEl(null);
	};

	// Handle select floor
	const handleSelectFloor = (floor: any) => {
		// Dispatch selected floor to appState
		dispatch(
			selectFloor({
				...selectedBuilding,
				buildingName: selectedBuilding.name,
				buildingId: selectedBuilding.id,
				name: floor.name,
				id: floor.id,
			})
		);

		// Unanchor menu
		setFloorAnchorEl(null);
	};

	// Handlers (Menu)
	const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleFloorClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setFloorAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setFloorAnchorEl(null);
	};

	// Handlers (dialog)
	const handleOpenDialog = (option: string) => {
		setDialog({ ...dialog, [option]: true });
	};
	const handleCloseDialog = () => {
		setDialog({
			building: false,
			floor: false,
			space: false,
		});
	};

	// Effects
	// Init
	useEffect(() => {
		// If no buildings in array, compile a list from the clients / sites
		if (!Boolean(buildings.length) && !isEmpty(clientsStore.data)) {
			compileBuildings();
		}

		// If no building has been selected, select it
		if (!Boolean(selectedBuilding.id) && buildings.length) {
			handleSelectBuilding(buildings[0]);
		}
		// eslint-disable-next-line
	}, [buildings, clientsStore.data]);

	// Auto-select first floor when a new building is selected
	useEffect(() => {
		// If floors exist, select the first floor
		// This only changes when a new building is selected, or the floor array changes
		if (Boolean(floors.length)) {
			dispatch(
				selectFloor({
					...selectedBuilding,
					buildingName: selectedBuilding.name,
					buildingId: selectedBuilding.id,
					name: floors[0].name,
					id: floors[0].id,
				})
			);
		}

		// If there's a selected building and data in the store, set the floors to the selected building's floors locally
		if (
			Boolean(selectedBuilding.id && buildingStore.data[selectedBuilding.id])
		) {
			setFloors(buildingStore.data[selectedBuilding.id].floors);
		}
		// eslint-disable-next-line
	}, [selectedBuilding.id, floors, buildingStore.data]);

	// Loading
	if (clientsStore.status === "loading") {
		return <LinearProgress style={{ width: "100%" }} />;
	}

	// No Clients
	if (clientsStore.succeeded && isEmpty(clientsStore.data)) {
		return (
			<Container>
				<Typography variant="h5" align="center">
					No Clients Found! Try refreshing the page to load them in.
				</Typography>
			</Container>
		);
	}

	// Convenience Variables (floor metadata and spaces)
	const buildingData = buildingStore.data[selectedBuilding.id];
	const floorData = find(buildingStore.data[selectedBuilding.id]?.floors, [
		"id",
		selectedFloor.id,
	]);
	// Include DB key in favoriteSpaces
	const favoriteSpaces = Object.keys(favoriteSpacesFetch.value).map(
		(key: string) => ({ key, ...favoriteSpacesFetch.value[key] })
	);

	// Render
	return (
		<Container style={{ display: "flex", flexWrap: "wrap" }}>
			{/* Glass Header and Control Container */}
			<GlassPaper style={{ width: "100%", margin: "1rem 0" }}>
				{/* Header */}
				<Grid container style={{ padding: "1rem 0" }}>
					<Grid container className={classes.container}>
						<Grid item xs={12}>
							<Typography variant="h5" style={{ width: "100%" }} paragraph>
								Spaces Access
							</Typography>
							<Typography variant="body1" style={{ width: "100%" }}>
								To access your spaces, select a building and a floor. You'll
								have the option to add spaces to your home screen, or drill in
								further and add specific devices.
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Divider style={{ width: "100%" }} />

				{/* Control */}
				<Grid container style={{ justifyContent: "space-between" }}>
					{/* Building Selector */}
					<Grid item xs={12} sm={4} className={classes.wrapper}>
						<div className={classes.container}>
							{/* Header */}
							<div className={classes.header}>
								<Typography variant="h5">
									{buildingStore.status === "loading"
										? "Loading Floors"
										: "Selected Building"}
								</Typography>

								{/* Open Buildings Menu (see Dialogs/Menus at the bottom of the file) */}
								{Boolean(buildings.length) && (
									<Chip
										avatar={<Avatar>{buildings.length}</Avatar>}
										label={`Building${buildings.length !== 1 ? "s" : ""}`}
										clickable
										color="primary"
										onClick={handleClickListItem}
									/>
								)}
							</div>

							{/* Building Display: Click opens Dialog for live weather and metadata */}
							<List component="nav" aria-label="Device settings">
								{/* Handle Building Fetch Status: LOADING, FAILED, FULFILLED */}
								{buildingStore.status === "loading" ? (
									// STATUS: LOADING
									<LinearProgress style={{ width: "100%" }} />
								) : (
									<Divider />
								)}

								{buildingStore?.status === "failed" ? (
									// STATUS: FAILED
									<ListItem
										button
										aria-haspopup="true"
										aria-controls="lock-menu"
										aria-label="when device is locked"
										onClick={() => handleSelectBuilding(selectedBuilding)}
										disabled={Boolean(buildingStore.status === "loading")}
										style={{ background: deepOrange[400] }}
									>
										<ListItemText
											primary={"Failed to get building data"}
											secondary={`Click here to try again`}
										/>
										<ListItemIcon style={{ justifyContent: "center" }}>
											<Refresh />
										</ListItemIcon>
									</ListItem>
								) : (
									// STATUS: FULFILLED
									<ListItem
										button
										aria-haspopup="true"
										aria-controls="lock-menu"
										aria-label="when device is locked"
										onClick={handleClickListItem}
										disabled={Boolean(buildingStore.status === "loading")}
									>
										<ListItemText
											primary={selectedBuilding.name}
											secondary={`${selectedBuilding.clientName} / ${selectedBuilding.siteName}`}
										/>
										<ListItemIcon style={{ justifyContent: "center" }}>
											<Info />
										</ListItemIcon>
									</ListItem>
								)}
							</List>
						</div>
					</Grid>

					{/* Floor Selector */}
					<Grid item xs={12} sm={7} className={classes.wrapper}>
						{/* Collapse while there are no floors (loading / disabled) */}
						<Collapse in={Boolean(floors.length)}>
							<div className={classes.container}>
								{/* Header */}
								<div className={classes.header}>
									<Typography variant="h5">Selected Floor</Typography>
									{/* Open Floors Menu (see Dialogs/Menus at the bottom of the file) */}
									{Boolean(floors.length) && (
										<Chip
											avatar={<Avatar>{floors.length}</Avatar>}
											label={`Floor${floors.length !== 1 ? "s" : ""}`}
											clickable
											color="secondary"
											onClick={handleFloorClickListItem}
										/>
									)}
								</div>

								{/* Floor Display */}
								<List component="nav" aria-label="Device settings">
									<Divider />
									<ListItem
										button
										aria-haspopup="true"
										aria-controls="floor-menu"
										aria-label="when floor is selected"
										onClick={() => handleOpenDialog("floor")}
									>
										<ListItemText
											primary={floorData ? floorData.name : ""}
											secondary={`${selectedBuilding.clientName} / ${selectedBuilding.siteName}`}
										/>
										<ListItemIcon style={{ justifyContent: "center" }}>
											<Info />
										</ListItemIcon>
									</ListItem>
								</List>
							</div>
						</Collapse>
					</Grid>
				</Grid>
			</GlassPaper>

			{/* Spaces */}
			<Collapse
				in={Boolean(floorData && floorData.spaces?.length)}
				style={{ width: "100%" }}
			>
				<List style={{ width: "100%", display: "flex" }}>
					<Grid container>
						{sortBy(floorData?.spaces, ["name"]).map((space: any) => {
							const favoriteData: IFavoriteData = find(favoriteSpaces, [
								"spaceId",
								space.id,
							]);

							// Payload for the DB in event of favoriteSpace click
							// ** No key, as this data wouldn't have existed in DB yet
							const favoriteDataPayload = {
								name: space.name,
								buildingId: buildingData.id,
								floorId: floorData.id,
								spaceId: space.id,
								deviceId: "",
								type: "space",
							};

							return (
								<Grid item xs={12} sm={4} key={space.id}>
									<FavoriteListItem
										data={space}
										favoriteData={favoriteData}
										favoriteDataPayload={favoriteDataPayload}
										buildingData={buildingData}
										floorData={floorData}
									/>
								</Grid>
							);
						})}
					</Grid>
				</List>
			</Collapse>

			{/* MENUS AND DIALOGS*/}

			{/* MENUS */}
			{/* Building Menu */}
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				{sortBy(buildings, ["name"]).map((building: any, idx: number) => (
					<MenuItem
						key={`${building.id}_${idx}`}
						selected={selectedBuilding.id === building.id}
						onClick={() => handleSelectBuilding(building)}
					>
						{building.name}
					</MenuItem>
				))}
			</Menu>

			{/* Floor Menu */}
			<Menu
				anchorEl={floorAnchorEl}
				open={Boolean(floorAnchorEl)}
				onClose={handleClose}
			>
				{sortBy(floors, ["name"]).map((floor: any) => (
					<MenuItem
						key={floor.id}
						selected={selectedFloor.id === floor.id}
						onClick={() => handleSelectFloor(floor)}
					>
						{floor.name}
					</MenuItem>
				))}
			</Menu>

			{/* DIALOGS */}
			{/* Building Dialog */}
			<Dialog
				fullScreen={mobile}
				onClose={handleCloseDialog}
				aria-labelledby="simple-dialog-title"
				open={dialog.building}
				classes={{ paper: classes.transparent, scrollPaper: classes.blur }}
			>
				{Boolean(mobile) && (
					<Button onClick={handleCloseDialog} style={{ padding: "1rem" }}>
						<Clear />
					</Button>
				)}

				<GlassPaper square={Boolean(mobile)}>
					{Boolean(buildingData) && (
						<DialogTitle id="simple-dialog-title">
							<ListItemText
								classes={{ primary: classes.primaryTextTitle }}
								primary={selectedBuilding.name}
								secondary={`${buildingData.address}, ${buildingData.city}, ${buildingData.state}, ${buildingData.zip}`}
							/>
						</DialogTitle>
					)}

					<Divider />

					{/* Body */}
					<Grid container style={{ display: "flex", justifyContent: "center" }}>
						{/* Weather Component */}
						<Grid item xs={12} style={{ padding: "1rem 2rem" }}>
							{Boolean(buildingData) && (
								<GlassPaper style={{ padding: "1rem" }}>
									<Weather
										backgroundImage={false}
										address={`${buildingData.address}, ${buildingData.city}, ${buildingData.state}, ${buildingData.zip}`}
										city={buildingData.city}
										id={buildingData.id}
									/>
								</GlassPaper>
							)}
						</Grid>

						{/* Air Quality Metadata */}
						{Boolean(
							buildingData?.metadata?.airQuality ||
								buildingData?.metadata?.temperature
						) && (
							<GlassPaper
								style={{
									display: "flex",
									justifyContent: "space-evenly",
									margin: "0 2rem 2rem",
									width: "100%",
								}}
							>
								<Grid container>
									<Grid item xs={12} sm={6}>
										{Boolean(
											buildingData &&
												buildingData.metadata &&
												!isEmpty(buildingData.metadata?.airQuality)
										) && (
											<MetadataDial
												colorByValue
												title="Building Air Quality"
												metadata={buildingData.metadata?.airQuality}
											/>
										)}
									</Grid>

									{/* Temperature Metadata */}
									<Grid item xs={12} sm={6}>
										{Boolean(
											buildingData &&
												buildingData.metadata &&
												!isEmpty(buildingData.metadata?.temperature)
										) && (
											<MetadataDial
												title="Building Temperature"
												metadata={buildingData.metadata?.temperature}
												appendToValue={"°"}
												min={0}
												max={120}
											/>
										)}
									</Grid>
								</Grid>
							</GlassPaper>
						)}
					</Grid>
				</GlassPaper>
			</Dialog>

			{/* Floor Dialog */}
			<Dialog
				onClose={handleCloseDialog}
				aria-labelledby="simple-dialog-title"
				open={dialog.floor}
			>
				<DialogTitle id="simple-dialog-title">
					{selectedFloor?.name}
				</DialogTitle>
				<List></List>
			</Dialog>

			{/* Space Dialog */}
			<Dialog
				onClose={handleCloseDialog}
				aria-labelledby="simple-dialog-title"
				open={dialog.space}
			>
				<DialogTitle id="simple-dialog-title">Space Info</DialogTitle>
				<List></List>
			</Dialog>
		</Container>
	);
};
