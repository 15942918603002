import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { functions } from "../../firebase/firebase";

const fetchEndpoint = functions.httpsCallable("fetchEndpoint");

export const fetchHistory: any = createAsyncThunk(
	"history/fetchHistory",
	async ({ api, id, name }: { api: string; id: string; name: string }) => {
		const response = await fetchEndpoint({
			route: `/history/point/${id}`,
			api,
		});
		const parsedResponse = JSON.parse(response.data);

		if (parsedResponse?.data) {
			return Object.assign(parsedResponse, { id, name });
		} else {
			return {};
		}
	}
);

export const historySlice = createSlice({
	name: "history",
	initialState: {
		data: {},
		status: "",
		error: "",
	},
	reducers: {},
	extraReducers: {
		[fetchHistory.pending]: (state, action) => {
			state.status = "loading";
		},
		[fetchHistory.fulfilled]: (state: any, action: any) => {
			state.status = "succeeded";
			if (action.payload?.id) {
				state.data[action.payload.id] = action.payload;
			}
		},
		[fetchHistory.rejected]: (state, action) => {
			state.status = "failed";
			state.error = action.error.message;
		},
	},
});

export default historySlice.reducer;
