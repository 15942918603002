import * as React from "react";

// MUI
import {
	Grid,
	LinearProgress,
	Typography,
	Theme,
	createStyles,
	makeStyles,
	lighten,
} from "@material-ui/core";

// Router
import { useLocation, useParams } from "react-router";

// Redux
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { fetchPublic } from "../../features/public/publicSlice";

// Interfaces
import { RootState } from "../../app/store";

// Util
import clsx from "clsx";
import { find } from "lodash";

// Components
import { GlassPaper } from "../../components/GlassPaper/GlassPaper";
import { IAQPublicDial } from "./IAQPublicDial";
import { IAQComponentSheet } from "../../components/DeviceWidgets/IAQ";

// Hooks
import usePollDevice from "../../hooks/useDeviceInterval";
import { getColorValue } from "../../util";

interface ISpaceProps {}

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: "flex",
			flexWrap: "wrap",
			padding: 0,
			height: "100vh",
			alignItems: "center",
		},
		// Generic
		background: {
			height: "100vh",
			width: "100vw",
			position: "fixed",
		},
		wrapper: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "1rem",
		},
	})
);

export const IAQDeviceDisplay: React.FC<ISpaceProps> = (props) => {
	const { buildingId, deviceId }: any = useParams();

	// Styles
	const classes = useStyles();

	// Redux
	const dispatch = useDispatch();

	// Redux data
	const publicStore = useAppSelector((state: RootState) => state.public);
	const buildingStore = publicStore.buildings; //buildings stored in redux
	const deviceStore = publicStore.devices; //devices stored in redux

	const buildingData = buildingStore?.[buildingId];
	const deviceData = deviceStore?.[deviceId];

	//! Device Polling Start													//
	const [pollData] = usePollDevice({ deviceData, route: `device/${deviceId}` });
	//! Device Polling End														//

	const location = useLocation();
	const dialOnly = location.search.includes("dialOnly");

	// If no buildingData, load it into the store
	React.useEffect(() => {
		if (!Boolean(buildingData) && buildingStore.status !== "loading") {
			dispatch(fetchPublic({ type: "buildings", id: buildingId }));
		}
		// eslint-disable-next-line
	}, []);

	// If no deviceData, load it into the store
	React.useEffect(() => {
		if (!Boolean(deviceData) && deviceStore.status !== "loading") {
			dispatch(fetchPublic({ type: "devices", id: deviceId }));
		}
		// eslint-disable-next-line
	}, []);

	console.log(
		find(pollData?.points, { name: "Air Quality Score" })?.data?.value
	);

	// ! DEP: PAGE REFRESH TO TAKE CARE OF OVERFLOW
	React.useEffect(() => {
		const interval = setInterval(() => {
			window.location.reload();
		}, 86400000);

		return () => {
			clearInterval(interval);
		};
	}, []);
	// ! DEP: PAGE REFRESH TO TAKE CARE OF OVERFLOW

	// Don't return anything if there's no device to return
	if (!deviceData) return null;

	// Convenience ob to check for existence of device
	const isDeviceType = {
		access: Boolean(deviceData.virtualProfile === "virtualAccess"),
		blinds: Boolean(deviceData.virtualProfile === "virtualBlinds"),
		iaq: Boolean(deviceData.virtualProfile === "virtualAirQuality"),
		lights: Boolean(deviceData.virtualProfile === "virtualLightSwitch"),
		schedule: Boolean(deviceData.virtualProfile === "virtualSchedule"),
		thermostat: Boolean(deviceData.virtualProfile === "virtualThermostat"),
	};

	const isLoading = Boolean(
		buildingStore.status === "loading" || deviceStore.status === "loading"
	);

	const score =
		find(pollData?.points, ["virtualDeviceSlot", "airQualityScore"]) ||
		find(deviceData.points, ["virtualDeviceSlot", "airQualityScore"]);

	// Handle background color
	let value = score?.data?.value;

	let { colorStart, colorEnd } = getColorValue(value);
	if (score?.color) {
		colorStart = lighten(`#${score.color}`, 0.5);
		colorEnd = lighten(`#${score.color}`, 0.7);
	}

	// Handle condition where no points are available
	if (deviceData && !Boolean(deviceData?.points.length)) {
		return (
			<Grid container style={{ margin: "1.5rem 0" }}>
				<Grid item xs={12} className={classes.wrapper}>
					<GlassPaper style={{ padding: "1.5rem" }}>
						<Typography style={{ width: "100%" }} variant="h5" paragraph>
							No Points Found
						</Typography>
						<Typography style={{ width: "100%" }} variant="body1">
							There are no data points that correspond to the associated device.
							If the problem persists, Contact your integration agent to confirm
							the device was set up properly.
						</Typography>
					</GlassPaper>
				</Grid>
			</Grid>
		);
	}

	return (
		<>
			<div
				className={clsx(classes.background)}
				style={{
					background: `linear-gradient(-90deg, ${colorStart} 0%, ${colorEnd} 75%)`,
				}}
			/>

			{isLoading ? (
				<LinearProgress style={{ width: "100%" }} />
			) : (
				<div className={classes.container}>
					{/* Background */}

					{/* Components */}
					{Boolean(
						isDeviceType["iaq"] &&
							(deviceData?.points?.length || pollData?.points?.length)
					) && (
						<Grid
							container
							style={{ margin: "2rem 0", justifyContent: "center" }}
						>
							<Grid
								item
								xs={12}
								sm={Boolean(dialOnly) ? 12 : 6}
								className={classes.wrapper}
							>
								<IAQPublicDial iaqDevice={pollData || deviceData} />
							</Grid>

							{/* Components */}
							{/* //!		ASSUMES LESS THAN 2 POINTS ON DEVICE MEANS NO COMPONENT DATA */}
							{Boolean(
								(!dialOnly && deviceData?.points?.length > 2) ||
									pollData?.points?.length > 2
							) && (
								<Grid item xs={12} sm={6} className={classes.wrapper}>
									<GlassPaper square>
										<IAQComponentSheet
											iaqPoints={pollData?.points || deviceData?.points}
										/>
									</GlassPaper>
								</Grid>
							)}
						</Grid>
					)}
				</div>
			)}
		</>
	);
};
