import * as React from "react";

import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";

// Util
import { isEmpty } from "lodash";
import { useHistory } from "react-router";
import { getDialRoute } from "./IAQBuildingTable";

interface IMetadata {
	avg: number;
	high: number;
	low: number;
	count: number;
}

const convertCamel = (str: string) => {
	if (typeof str !== "string") return "";

	return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
		return str.toUpperCase();
	});
};

interface IIAQMetadataTableProps {
	metadata: { [key: string]: IMetadata };
	location: {
		buildingId?: string;
		floorId?: string;
		spaceId?: string;
	};
	resource: string;
	size?: "small" | "medium" | undefined;
}

export const IAQMetadataTable = ({
	metadata,
	size,
	location,
	resource,
}: IIAQMetadataTableProps) => {
	const history = useHistory();

	let pathname = "/iaq/metadata/";
	if (location.buildingId) pathname += location.buildingId;
	if (location.floorId) pathname += `/${location.floorId}`;
	if (location.spaceId) pathname += `/${location.spaceId}`;

	return Boolean(metadata && !isEmpty(metadata)) ? (
		<TableContainer component={Paper}>
			<Table
				sx={{ minWidth: 650 }}
				aria-label="simple table"
				size={size ? size : "medium"}
			>
				<TableHead>
					<TableRow>
						<TableCell>Parameter</TableCell>
						<TableCell align="right">Average</TableCell>
						<TableCell align="right">High</TableCell>
						<TableCell align="right">Low</TableCell>
						<TableCell align="right">Count</TableCell>
						<TableCell align="right">Screen</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.keys(metadata).map((metaKey: string) => {
						let data: IMetadata = metadata[metaKey];

						return (
							<TableRow
								key={metaKey}
								sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
							>
								<TableCell component="th" scope="row">
									{convertCamel(metaKey)}
								</TableCell>
								<TableCell align="right">{data?.avg || ""}</TableCell>
								<TableCell align="right">{data?.high || ""}</TableCell>
								<TableCell align="right">{data?.low || ""}</TableCell>
								<TableCell align="right">{data?.count || ""}</TableCell>
								<TableCell align="right">
									<Button
										variant="contained"
										size={size}
										onClick={() =>
											history.push(
												getDialRoute({
													pathname,
													component: metaKey,
													type: "dialOnly",
													resource,
												})
											)
										}
									>
										Dial
									</Button>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell colSpan={6}></TableCell>
					</TableRow>
					<TableRow>
						<TableCell colSpan={5}></TableCell>
						<TableCell align="right">
							<Button
								variant="contained"
								size={size}
								onClick={() =>
									history.push(
										getDialRoute({
											pathname,
											component: "all",
											type: "dialOnly",
											resource,
										})
									)
								}
							>
								All
							</Button>
						</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	) : (
		<Typography variant="body1">No building metadata found</Typography>
	);
};
