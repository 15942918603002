import * as React from "react";

// MUI
import {
	Divider,
	Grid,
	LinearProgress,
	Theme,
	createStyles,
	makeStyles,
	Typography,
	Breadcrumbs,
	Button,
} from "@material-ui/core";

// Router
import { useHistory, useParams } from "react-router";

// Redux
import { fetchBuilding } from "../../features/buildings/buildingsSlice";
import { fetchDevice } from "../../features/devices/devicesSlice";
import { fetchHistory } from "../../features/history/historySlice";

import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";

// Interfaces
import { RootState } from "../../app/store";

// DB
import { useFirebaseConnect } from "react-redux-firebase";

// Util
import { find, isEmpty } from "lodash";

// Components
import { GlassPaper } from "../../components/GlassPaper/GlassPaper";
import { HistoryDataDialog } from "../../components/Histories/HistoryDataDialog";
import { HistoryLineChart } from "../../components/Histories/HistoryLineChart";

// Widgets
import { IAQComponentCard } from "../../components/DeviceWidgets/IAQ/IAQComponentSheet/IAQComponentCard";
import { IAQDeviceDial } from "../../components/DeviceWidgets/IAQ/IAQDeviceDial/IAQDeviceDial";

// Icons
import { ReactComponent as PM25 } from "../../static/svg/pm25.svg";
import { ReactComponent as CO2 } from "../../static/svg/c02.svg";
import { ReactComponent as Humidity } from "../../static/svg/humidity.svg";
import { ReactComponent as Tvocs } from "../../static/svg/tvocs.svg";
import { ReactComponent as Temp } from "../../static/svg/climate.svg";

interface ISpaceProps {}

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		componentWrapper: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
	})
);

export const DeviceScreen: React.FC<ISpaceProps> = (props) => {
	const { buildingId, floorId, spaceId, deviceId }: any = useParams();
	const [historyDataDialog, setHistoryDataDialog] = React.useState(null);

	// HOOKS
	// Router
	const history = useHistory();

	// Styles
	const classes = useStyles();

	// Redux
	const dispatch = useDispatch();

	// STORE
	// Firebase
	const api = useAppSelector((state: RootState) => state.firebase.profile.api);
	const uid = useAppSelector((state: any) => state.firebase?.auth?.uid);
	useFirebaseConnect([`users/${uid}/control`]);

	// Redux data
	const buildingStore = useAppSelector((state: RootState) => state.buildings); //buildings stored in redux
	const deviceStore = useAppSelector((state: RootState) => state.devices); //devices stored in redux
	const historyStore = useAppSelector((state: RootState) => state.history); //history stored in redux

	const buildingData = buildingStore.data?.[buildingId];
	const deviceData = deviceStore.data?.[deviceId];

	// If no buildingData, load it into the store
	React.useEffect(() => {
		if (!Boolean(buildingData) && buildingStore.status !== "loading") {
			dispatch(fetchBuilding({ api, id: buildingId }));
		}
		// eslint-disable-next-line
	}, []);

	// If no deviceData, load it into the store
	React.useEffect(() => {
		if (!Boolean(deviceData) && deviceStore.status !== "loading") {
			dispatch(
				fetchDevice({ api, buildingId, floorId, spaceId, id: deviceId })
			);
		}
		// eslint-disable-next-line
	}, []);

	// If no pointHistory, load it into the store
	React.useEffect(() => {
		if (
			Boolean(deviceData) &&
			Boolean(deviceData?.points?.length) &&
			historyStore.status !== "loading"
		) {
			deviceData.points.forEach((point: any) => {
				if (point.id) {
					dispatch(fetchHistory({ api, id: point?.id, name: point.name }));
				}
			});
		}
		// eslint-disable-next-line
	}, [deviceData?.points]);

	// Handle history dialog
	const clearHistoryData = React.useCallback(() => {
		setHistoryDataDialog(null);
	}, []);

	// Don't return anything if there's no device to return
	if (!deviceData) return null;

	// If building data exists, get all associated data with spaces
	const building = buildingStore.data?.[buildingId] || {
		floors: [],
	};
	const floor = find(building.floors, ["id", floorId]) || {
		spaces: [],
	};
	const space = find(floor.spaces, ["id", spaceId]) || {
		devices: [],
	};

	// Convenience ob to check for existence of device
	const isDeviceType = {
		access: Boolean(deviceData.virtualProfile === "virtualAccess"),
		blinds: Boolean(deviceData.virtualProfile === "virtualBlinds"),
		iaq: Boolean(deviceData.virtualProfile === "virtualAirQuality"),
		lights: Boolean(deviceData.virtualProfile === "virtualLightSwitch"),
		schedule: Boolean(deviceData.virtualProfile === "virtualSchedule"),
		thermostat: Boolean(deviceData.virtualProfile === "virtualThermostat"),
	};

	const isLoading = Boolean(
		buildingStore.status === "loading" || deviceStore.status === "loading"
	);

	const co2 = find(deviceData.points, ["virtualDeviceSlot", "co2"]);
	const humidity = find(deviceData.points, ["virtualDeviceSlot", "humidity"]);
	const pm25 = find(deviceData.points, ["virtualDeviceSlot", "pm25"]);
	const voc = find(deviceData.points, ["virtualDeviceSlot", "voc"]);
	const temp = find(deviceData.points, ["virtualDeviceSlot", "tempDisplay"]);

	return (
		<>
			{isLoading ? (
				<LinearProgress style={{ width: "100%" }} />
			) : (
				<div style={{ display: "flex", flexWrap: "wrap", padding: 0 }}>
					{/* Metadata Components */}
					<div
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "space-evenly",
						}}
					>
						<GlassPaper square style={{ width: "100%" }}>
							<Grid container>
								{/* Header */}
								<Grid
									item
									xs={12}
									style={{
										display: "flex",
										justifyContent: "space-between",
										flexWrap: "wrap",
										alignItems: "center",
										padding: "0 1rem",
									}}
								>
									<Typography
										variant="h5"
										style={{ padding: "1rem", fontWeight: 700, flexGrow: 1 }}
									>
										{deviceData.name}
									</Typography>

									<div style={{ display: "flex" }}>
										<Breadcrumbs>
											<Button
												onClick={() =>
													history.push(
														`/space/${building.id}/${floor.id}/${space.id}`
													)
												}
											>
												{space.name}
											</Button>
											<Typography variant="body1" style={{ fontWeight: 700 }}>
												{deviceData.name}
											</Typography>
										</Breadcrumbs>
									</div>
								</Grid>

								<Divider style={{ width: "100%" }} />
							</Grid>
						</GlassPaper>
					</div>

					<Divider style={{ width: "100%" }} />

					{/* Components */}
					{Boolean(isDeviceType["iaq"]) && (
						<Grid container style={{ margin: "2rem 0" }}>
							<Grid
								item
								xs={12}
								sm={6}
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									padding: "1rem",
								}}
							>
								<IAQDeviceDial iaqDevice={deviceData} />
							</Grid>

							{/* Components */}
							{Boolean(deviceData?.points?.length > 2) && (
								<Grid
									item
									xs={12}
									sm={6}
									style={{
										display: "flex",
										flexWrap: "wrap",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<GlassPaper
										square
										style={{
											display: "flex",
											padding: "2rem",
											flexDirection: "column",
										}}
									>
										{/* CO2 */}
										{Boolean(co2) && (
											<Grid item xs={12} className={classes.componentWrapper}>
												{
													<IAQComponentCard
														name={"CO2"}
														iaqComponent={co2}
														icon={
															<CO2
																height="1.5rem"
																style={{ margin: "0 .5rem" }}
															/>
														}
													/>
												}
											</Grid>
										)}

										{/* Humidity */}
										{Boolean(humidity) && (
											<Grid item xs={12} className={classes.componentWrapper}>
												{
													<IAQComponentCard
														name={"Humidity"}
														iaqComponent={humidity}
														icon={
															<Humidity
																height="1.5rem"
																style={{ margin: "0 .5rem" }}
															/>
														}
													/>
												}
											</Grid>
										)}

										{/* PM25 */}
										{Boolean(pm25) && (
											<Grid item xs={12} className={classes.componentWrapper}>
												{
													<IAQComponentCard
														name="PM25"
														iaqComponent={pm25}
														icon={
															<PM25
																height="1.5rem"
																style={{ margin: "0 .5rem" }}
															/>
														}
													/>
												}
											</Grid>
										)}

										{/* Tvocs */}
										{Boolean(voc) && (
											<Grid item xs={12} className={classes.componentWrapper}>
												{
													<IAQComponentCard
														name="VOCs"
														iaqComponent={voc}
														icon={
															<Tvocs
																height="1.5rem"
																style={{ margin: "0 .5rem" }}
															/>
														}
													/>
												}
											</Grid>
										)}

										{/* Temp */}
										{Boolean(temp) && (
											<Grid item xs={12} className={classes.componentWrapper}>
												{
													<IAQComponentCard
														name="Temp"
														iaqComponent={temp}
														icon={
															<Temp
																height="1.5rem"
																style={{ margin: "0 .5rem" }}
															/>
														}
													/>
												}
											</Grid>
										)}
									</GlassPaper>
								</Grid>
							)}
						</Grid>
					)}

					{/* Histories */}
					<Grid container style={{ marginBottom: "5rem" }}>
						{/* Iterate through points */}
						{deviceData?.points?.map((point: any) => {
							// Look for historyData in the store (loads in useEffect above)
							const historyData = historyStore.data[point.id];

							// If it exists, return a GlassPaper HistoryLineChart
							return (
								Boolean(historyData && historyData?.data?.length) && (
									<Grid key={point.id} item xs={12} sm={6}>
										<Button
											style={{ width: "100%", padding: 0 }}
											onClick={() => setHistoryDataDialog(historyData)}
										>
											<GlassPaper
												// disableBorders
												disableEffects
												square
												style={{ padding: "1rem 0", width: "100%" }}
											>
												<HistoryLineChart
													label={historyData?.name || ""}
													historyData={historyData}
													height={120}
													sampleNth={10}
													showTooltip={false}
												/>
											</GlassPaper>
										</Button>
									</Grid>
								)
							);
						})}
					</Grid>
				</div>
			)}

			{/* DIALOG */}
			<HistoryDataDialog
				historyData={historyDataDialog}
				devicePoints={deviceData?.points}
				open={!isEmpty(historyDataDialog)}
				clearHistoryData={clearHistoryData}
			/>
		</>
	);
};
