import React from "react";

// MUI
import {
	Collapse,
	Divider,
	IconButton,
	LinearProgress,
	Typography,
	useMediaQuery,
} from "@material-ui/core";

// Styles
import {
	Theme,
	createStyles,
	makeStyles,
	useTheme,
} from "@material-ui/core/styles";

// Icons
import { Maximize, Minimize, Refresh } from "@material-ui/icons";

// Redux
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { RootState } from "../../app/store";

// Actions
import { fetchWeather } from "../../features/weather/weatherSlice";

// Util
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			background: "transparent",
		},
		wrapper: {},
		refreshWrapper: {
			width: "100%",
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
		componentWrapper: {
			padding: "1rem 2rem",
		},
		spacer: {
			padding: "1rem",
		},
		inline: {
			display: "flex",
			flexDirection: "row-reverse",
			justifyContent: "space-between",
		},
	})
);

interface IWeatherProps {
	backgroundImage?: boolean;
	address: string;
	city: string;
	id: string;
	square?: boolean;
}

export const Weather: React.FC<IWeatherProps> = ({
	address,
	city,
	id,
	backgroundImage = true,
	square = false,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	// State
	const [refresh, setRefresh] = React.useState(false);
	const [collapse, setCollapse] = React.useState(mobile);

	// Redux
	const dispatch = useDispatch();
	const weather = useAppSelector((state: RootState) => state.weather); //buildings fetched and stored on selection

	// Weather fetch / refresh
	React.useEffect(() => {
		if (!weather.data[id]) {
			dispatch(fetchWeather({ address, id }));
		}

		if (refresh) {
			dispatch(fetchWeather({ address, id }));
			setRefresh(false);
		}
		// eslint-disable-next-line
	}, [address, refresh]);

	// Mobile
	React.useEffect(() => {
		setCollapse(mobile);
	}, [mobile]);

	const weatherData = weather.data[id]?.[0] || {};
	const weatherDateObject = weatherData.endTime
		? new Date(weatherData.endTime)
		: new Date();
	const weatherDateString = weatherDateObject
		.toDateString()
		.split(" ")
		.slice(1, 3)
		.join(" ");

	// Handlers
	const handleRefresh = () => {
		setRefresh(true);
	};

	const handleCollapse = () => {
		setCollapse(!collapse);
	};

	const dayImage =
		"url(https://images.unsplash.com/photo-1528469138590-fa12d3193392?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=332&q=80)";
	const nightImage =
		"url(https://images.unsplash.com/photo-1493514789931-586cb221d7a7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=751&q=80)";

	if (Boolean(weather.status === "loading")) {
		return (
			<div className={classes.wrapper}>
				<LinearProgress />
			</div>
		);
	}

	return (
		<div>
			<div className={classes.container}>
				{/* Loading Weather */}
				<div className={classes.wrapper}>
					{/* Background based on day/night */}
					<div
						style={{
							borderRadius: 21,
							backgroundSize: "cover",
							backgroundImage: !backgroundImage
								? "none"
								: weatherData?.isDaytime
								? dayImage
								: nightImage,
						}}
					>
						{/* Refresh Button */}
						<div className={classes.refreshWrapper}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<IconButton onClick={handleRefresh}>
									<Refresh />
								</IconButton>
								<Typography variant="body1">Local Weather</Typography>
							</div>
							<IconButton onClick={handleCollapse}>
								{collapse ? <Maximize /> : <Minimize />}
							</IconButton>
						</div>

						<Divider />

						{/* City / Temp wrapper */}
						<div
							className={clsx({
								[classes.inline]: !mobile,
							})}
						>
							{/* City Wrapper */}
							<Collapse in={!Boolean(collapse)}>
								<div className={classes.componentWrapper}>
									<Typography variant="h4" style={{ fontWeight: 700 }}>
										{city || ""}
									</Typography>
									<Typography variant="overline" style={{ fontSize: "1rem" }}>
										{weatherDateString || ""}
									</Typography>
								</div>
							</Collapse>

							{/* Temperature */}
							{Boolean(weatherData?.temperature) && (
								<div
									className={classes.componentWrapper}
									style={{ paddingBottom: "1rem" }}
								>
									<Typography
										variant="h2"
										style={{
											fontWeight: 700,
										}}
									>
										{`${
											weatherData.temperature
												? weatherData.temperature + "°F"
												: ""
										}`}
									</Typography>
								</div>
							)}
						</div>

						{/* Description */}
						<Collapse in={!Boolean(collapse)}>
							<div className={classes.componentWrapper}>
								<Divider />
								<Typography
									variant="overline"
									style={{ lineHeight: 1, textAlign: "center" }}
								>
									{weatherData.shortForecast
										? weatherData.shortForecast
										: "No data to display"}
								</Typography>
							</div>
						</Collapse>
					</div>
				</div>
			</div>
		</div>
	);
};
