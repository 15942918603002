import * as React from "react";

// Redux
import { useAppSelector } from "../../../../app/hooks";

// Interfaces
import { IPoint } from "../../../../interfaces";
import { RootState } from "../../../../app/store";
import { IFavoriteDataPayload } from "../../../FavoriteListItem/FavoriteListItem";

// MUI
import {
	Button,
	Grid,
	IconButton,
	Typography,
	Theme,
	makeStyles,
	createStyles,
} from "@material-ui/core";

// Colors
import { yellow } from "@material-ui/core/colors";

// Icons
import { Star } from "@material-ui/icons";

// Components
import { GlassDial } from "../../../GlassDial/GlassDial";

// Hooks
import { useHistory } from "react-router";
import { useFirebase } from "react-redux-firebase";
import { useSnackbar } from "notistack";

// Util
import clsx from "clsx";
import { find } from "lodash";
import { GlassCard } from "../../../GlassCard/GlassCard";
import { IAQComponentSheet } from "..";

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		favoriteButtonContainer: {
			zIndex: 99,
			position: "absolute",
			right: 0,
			top: 0,
		},
		secondary: {
			position: "absolute",
			left: 0,
			paddingLeft: "1rem",
		},
		componentWrapper: {
			display: "flex",
			paddingLeft: "1rem",
		},
		iconWrapper: {
			display: "flex",
			flexWrap: "wrap",
			alignItems: "center",
			justifyContent: "center",
		},
		displayStringWrapper: {
			display: "flex",
			alignItems: "center",
		},
		// Favorite
		favorite: {
			color: yellow[500],
			stroke: "rgba(0,0,0,.25)",
		},
	})
);

interface IIAQData {
	id: string;
	name: string;
	points: IPoint | any[];
	virtualProfile: string;
}

interface IAirQualityContainerProps {
	iaqDevice: IIAQData;
	style?: any;
}

export const IAQCard: React.FC<IAirQualityContainerProps> = ({
	iaqDevice,
	style,
}) => {
	const classes = useStyles();
	const history = useHistory();
	const rrf = useFirebase();
	const snackbar = useSnackbar();

	// Favorite Spaces: Redux
	const uid = useAppSelector((state: any) => state.firebase?.auth?.uid);
	const control = useAppSelector(
		(state: any) => state.firebase.ordered?.users?.[uid]?.control
	);

	// Favorite Spaces: Favorite Devices
	const favoriteDevicesFetch = Array.isArray(control)
		? find(control, ["key", "favoriteDevices"]) || { key: "", value: {} }
		: { key: "", value: {} };
	const favoriteDevices = Object.keys(favoriteDevicesFetch.value).map(
		(key: string) => ({ key, ...favoriteDevicesFetch.value[key] })
	);

	// Redux device data
	const deviceStore = useAppSelector((state: RootState) => state.devices); //devices fetched and stored on selection
	const deviceData = deviceStore.data?.[iaqDevice.id];

	// ** CONTROL POINT: If no device points, return null. Everything below assumes deviceData !== void 0
	if (Boolean(!deviceData) || Boolean(!deviceData.points?.length)) {
		return null;
	}

	// Convenience
	const { buildingId, floorId, spaceId, id } = deviceData;
	const favoriteDevice = find(favoriteDevices, ["deviceId", id]);

	// IAQ Device points
	const score = find(deviceData.points, [
		"virtualDeviceSlot",
		"airQualityScore",
	]);

	const clampedScore =
		score?.data?.value >= 100
			? 100
			: !isNaN(Number(score?.data?.value))
			? Math.round(Number(score.data.value))
			: 0;

	// TODO: Description doesn't have a virtualDeviceSlot
	const description =
		find(deviceData.points, ["virtualDeviceSlot", "description"]) ||
		find(deviceData.points, ["name", "air Quality Description Control Point"]);

	// ** CONTROL POINT: If no score.id (IAQ is without it's number component, return null;)
	if (!Boolean(score?.id)) return null;

	// Favorite Devices
	const favoriteDataPayload: IFavoriteDataPayload = {
		name: deviceData.name,
		buildingId,
		floorId,
		spaceId,
		deviceId: id,
		type: "device",
	};

	// Handle favorite
	const handleFavorite = () => {
		const favoriteDevicesRoute = `users/${uid}/control/favoriteDevices`;

		if (Boolean(favoriteDevice)) {
			const removeRole = rrf.set(
				`${favoriteDevicesRoute}/${favoriteDevice.key}`,
				null
			);
			removeRole.then((res: any) => {
				snackbar.enqueueSnackbar(
					`${favoriteDevice.name} removed from your favorites`,
					{
						variant: "warning",
						autoHideDuration: 2000,
					}
				);
			});
		} else {
			const roleAdded = rrf.push(favoriteDevicesRoute, favoriteDataPayload);
			roleAdded.then((res: any) => {
				snackbar.enqueueSnackbar(`${deviceData.name} added to your favorites`, {
					variant: "success",
					autoHideDuration: 2000,
				});
			});
		}
	};

	return (
		<GlassCard
			title={
				<div>
					{Boolean(deviceData?.name) && (
						<Typography variant="body2" paragraph>
							{deviceData.name}
						</Typography>
					)}
					<div className={classes.favoriteButtonContainer}>
						<IconButton onClick={() => handleFavorite()}>
							<Star
								className={clsx({
									[classes.favorite]: Boolean(favoriteDevice),
								})}
							/>
						</IconButton>
					</div>
				</div>
			}
		>
			{/* Score Dial */}
			<Grid container style={{ justifyContent: "center" }}>
				<Grid
					item
					xs={12}
					sm={6}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Button
						style={{ borderRadius: "100%" }}
						onClick={() =>
							history.push(`/device/${buildingId}/${floorId}/${spaceId}/${id}`)
						}
					>
						<GlassDial
							id={score.id}
							value={clampedScore}
							label={description ? description.data.value : "IAQ"}
							size={125}
							colorByValue
						/>
					</Button>
				</Grid>

				{/* Components */}
				{Boolean(deviceData?.points?.length > 3) && (
					<Grid item xs={12} sm={6}>
						<IAQComponentSheet iaqPoints={deviceData?.points} size="small" />
					</Grid>
				)}
			</Grid>
		</GlassCard>
	);
};
