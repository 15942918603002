import React from "react";
// Get max height
var html = document.documentElement;

// Hook
export const useWindowSize = () => {
	const [windowSize, setWindowSize]: any = React.useState({
		width: undefined,
		height: undefined,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: Math.max(html.scrollHeight, window.innerHeight),
		});
	};

	// Resize when the scroll height changes
	React.useEffect(() => {
		handleResize();
		// eslint-disable-next-line
	}, [html.scrollHeight, window.innerHeight]);

	// Resize when the window resizes
	React.useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowSize;
};
