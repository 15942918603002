import * as React from "react";

import { GlassDial } from "../../../GlassDial/GlassDial";
import { Grid, useTheme, useMediaQuery } from "@material-ui/core";
import Slider from "@mui/material/Slider";

// Firebase
import { functions } from "../../../../firebase/firebase";
import { useSelector } from "react-redux";
const postEndpoint = functions.httpsCallable("postEndpoint");

const marks = [
	{
		value: 25,
		label: "25%",
	},
	{
		value: 50,
		label: "50%",
	},
	{
		value: 75,
		label: "75%",
	},
];

interface ILightSliderProps {
	pointData: any;
}

export const LightSlider = ({ pointData }: ILightSliderProps) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.only("xs"));

	const api = useSelector((state: any) => state.firebase.profile.api);
	const user = useSelector((state: any) => state.firebase.profile.email);

	let [value, setValue] = React.useState(pointData?.data?.value);
	let [loading, setLoading] = React.useState(false);

	const handleChange = (event: Event, newValue: number | number[]) => {
		setValue(newValue);
	};

	const commitChange = (
		event: React.SyntheticEvent | Event,
		newValue: number | Array<number>
	) => {
		setLoading(true);
		postEndpoint({
			api,
			user,
			route: `point/${pointData?.id}`,
			payload: value,
		})
			.then((res: any) => {
				setLoading(false);
			})
			.catch((e: any) => {
				setLoading(false);
			});
	};

	return (
		<Grid container>
			<Grid
				item
				xs={12}
				sm={4}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<GlassDial
					id={pointData?.id}
					value={pointData?.data?.value}
					loading={loading}
				/>
			</Grid>
			{mobile && (
				<span style={{ width: "100%", display: "flex", height: "20px" }} />
			)}
			<Grid
				item
				xs={12}
				sm={8}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Slider
					min={0}
					max={100}
					getAriaLabel={() => "Cooling Setpoints"}
					value={value}
					onChange={handleChange}
					onChangeCommitted={commitChange}
					valueLabelDisplay="auto"
					step={1}
					marks={marks}
					getAriaValueText={() => "Light Slider"}
					disableSwap
				/>
			</Grid>
		</Grid>
	);
};
