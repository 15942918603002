import * as React from "react";

// Redux
import { useAppSelector } from "../../app/hooks";

// Interfaces
import { IPoint } from "../../interfaces";
import { RootState } from "../../app/store";

// MUI
import { Grid } from "@material-ui/core";

// Util
import { find } from "lodash";

// Components
import { GlassDial } from "../../components/GlassDial/GlassDial";

interface IIAQData {
	id: string;
	name: string;
	points: IPoint | any[];
	virtualProfile: string;
}

interface IAirQualityContainerProps {
	iaqDevice: any;
	size?: number;
}

export const IAQPublicDial: React.FC<IAirQualityContainerProps> = ({
	iaqDevice,
	size = 250,
}) => {
	// Redux device data
	const deviceStore = useAppSelector(
		(state: RootState) => state.public.devices
	); //devices fetched and stored on selection
	const deviceData = iaqDevice || deviceStore?.[iaqDevice.id];

	// ** CONTROL POINT: If no device points, return null. Everything below assumes deviceData !== void 0
	if (Boolean(!deviceData) || Boolean(!deviceData.points?.length)) {
		return null;
	}

	// IAQ Device points
	const score = find(deviceData.points, [
		"virtualDeviceSlot",
		"airQualityScore",
	]);

	// TODO: Description doesn't have a virtualDeviceSlot
	const description =
		find(deviceData.points, ["virtualDeviceSlot", "description"]) ||
		find(deviceData.points, ["name", "air Quality Description Control Point"]);

	// ** CONTROL POINT: If no score.id (IAQ is without it's number component, return null;)
	if (!Boolean(score?.id)) return null;

	const clampedScore =
		score?.data?.value >= 100
			? 100
			: !isNaN(Number(score?.data?.value))
			? Math.round(Number(score.data.value))
			: 0;

	return (
		<div>
			{/* Score Dial */}
			<Grid container style={{ padding: "1rem 0" }}>
				<Grid
					item
					xs={12}
					style={{ display: "flex", justifyContent: "center" }}
				>
					<GlassDial
						id={score.id}
						value={clampedScore}
						label={description ? description.data.value : "IAQ"}
						size={size}
						fontSize={"4rem"}
						color={Boolean(score?.color) ? `#${score.color}` : ""}
						colorByValue={Boolean(!score?.color)}
					/>
				</Grid>
			</Grid>
		</div>
	);
};
