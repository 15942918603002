import * as React from "react";

// MUI
import { LinearProgress } from "@material-ui/core";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { fetchPublic } from "../../features/public/publicSlice";

// Components
import IAQClientTable from "./IAQClientTable";
import { IAQDeviceDisplay } from "./IAQDeviceDisplay";
import IAQBuildingTable from "./IAQBuildingTable";

interface IIAQPublicPageProps {}

export const IAQPublicPage: React.FC<IIAQPublicPageProps> = (props) => {
	// Redux
	const dispatch = useDispatch();
	const params: any = useParams();

	// Store
	const authStore = useSelector((state: any) => state.firebase.auth);
	const publicStore = useSelector((state: any) => state.public);

	const clientStore = publicStore.clients;

	// Convenience
	const hasClients = Boolean(Object.keys(clientStore).length);

	// Get clients and store in public
	React.useEffect(() => {
		if (!hasClients && publicStore.status !== "loading" && authStore.isLoaded) {
			dispatch(fetchPublic({ type: "clients" }));
		}
		// eslint-disable-next-line
	}, [authStore]);

	if (hasClients && !params.buildingId) {
		return <IAQClientTable clients={clientStore} />;
	}

	if (hasClients && Boolean(params.buildingId && !params.deviceId)) {
		return <IAQBuildingTable />;
	}

	if (Boolean(params.buildingId && params.deviceId)) {
		return <IAQDeviceDisplay />;
	}

	return <LinearProgress />;
};
