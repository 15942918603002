import React, { ReactElement } from "react";

// MUI
import {
	Accordion,
	AccordionSummary,
	Typography,
	LinearProgress,
	AccordionDetails,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Divider,
	Button,
	styled,
} from "@mui/material";

// Icons
import { ExpandMore, Language } from "@material-ui/icons";

// Hooks
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

// Util
import { orderBy } from "lodash";

// Redux
import { Section } from "../../components/Section/Section";
import { Title } from "../../components/Title/Title";

interface Props {
	clients: any;
}

const CustomizedListItem = styled(ListItem)`
	.MuiListItemSecondaryAction-root {
		width: 66%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: right;
	}
`;

export default function IAQClientTable({
	clients,
}: Props): ReactElement | null {
	const history = useHistory();

	const publicStore = useSelector((state: any) => state.public);

	const [expanded, setExpanded] = React.useState<string | false>(false);

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	// const buildings = publicStore.buildings;
	// const building = buildings[params.buildingId];

	// React.useEffect(() => {
	// 	if (!building && params.buildingId) {
	// 		dispatch(fetchPublic({ id: params.buildingId, type: "buildings" }));
	// 	}
	// }, [building, params.buildingId]);

	// Fetching
	if (publicStore.status === "loading") {
		return <LinearProgress />;
	}

	return (
		<div>
			<Section style={{ borderRadius: 0 }}>
				<Title
					title="Clients"
					button={<Button onClick={() => history.push("/")}>Back</Button>}
				/>
				{orderBy(Object.values(clients), ["name"]).map((client: any) => {
					return (
						<Accordion
							key={client?.id}
							expanded={expanded === client?.id}
							onChange={handleChange(client?.id)}
							TransitionProps={{ unmountOnExit: true }}
							disabled={!Boolean(client?.sites?.length)}
						>
							<AccordionSummary
								expandIcon={<ExpandMore />}
								aria-controls="panel1bh-content"
								id="panel1bh-header"
							>
								<Typography sx={{ width: "33%", flexShrink: 0 }}>
									{client?.name || ""}
								</Typography>
								<Typography sx={{ color: "text.secondary" }}>
									{!Boolean(client?.sites?.length)
										? "No sites found"
										: client?.contact || ""}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<List style={{ display: "flex" }}>
									<ListItem sx={{ width: "33%", flexShrink: 0 }}>
										<ListItemText primary="Sites" />
									</ListItem>
									<ListItem>
										<ListItemText
											primary="Buildings"
											style={{ textAlign: "right" }}
										/>
									</ListItem>
								</List>
								<Divider />
								<List style={{ display: "flex", flexWrap: "wrap" }}>
									{orderBy(client?.sites, ["name"]).map((site: any) => {
										return (
											<CustomizedListItem
												key={site?.id}
												secondaryAction={orderBy(site?.buildings, ["name"]).map(
													(building: any) => {
														return (
															<Button
																variant="contained"
																key={building?.id}
																onClick={() =>
																	history.push(`/iaq/${building?.id}`)
																}
																disabled={Boolean(!building?.id)}
																style={{
																	marginLeft: "1rem",
																	marginBottom: ".5rem",
																}}
															>
																{building?.name || ""}
															</Button>
														);
													}
												)}
											>
												<ListItemIcon>
													<Language />
												</ListItemIcon>
												<ListItemText
													primary={site?.name || ""}
													secondary={
														Boolean(site?.buildings?.length)
															? `${site.buildings.length} building${
																	site.buildings.length > 1 ? "s" : ""
															  }`
															: "No buildings found"
													}
												/>
											</CustomizedListItem>
										);
									})}
								</List>
							</AccordionDetails>
						</Accordion>
					);
				})}
			</Section>
		</div>
	);
}
