import { lighten } from "@material-ui/core";
import {
	amber,
	blue,
	deepOrange,
	green,
	lightBlue,
	lightGreen,
	red,
} from "@material-ui/core/colors";

export const getColorValue = (value: string | number | null) => {
	let colorStart = "#00bc9b",
		colorEnd = "#5eaefd";

	// Good
	if (typeof value === "number") {
		if (value >= 90) {
			colorStart = green[300];
			colorEnd = lighten(colorStart, 0.3);
		}
		// Okay
		if (value < 90 && value >= 80) {
			colorStart = lightGreen[400];
			colorEnd = lighten(colorStart, 0.3);
		}
		// Warn
		if (value < 80 && value > 60) {
			colorStart = amber[500];
			colorEnd = lighten(colorStart, 0.3);
		}
		// Danger
		if (value <= 60 && value > 20) {
			colorStart = deepOrange[300];
			colorEnd = lighten(colorStart, 0.3);
		}
		// Fail
		if (value <= 20) {
			colorStart = red[300];
			colorEnd = lighten(colorStart, 0.3);
		}
	}

	return { colorStart, colorEnd };
};

export const getTempColorValue = (value: number) => {
	let colorStart = "#28668B",
		colorEnd = "#B14D4F";

	if (typeof value === "number") {
		// Very Hot
		if (value >= 95) {
			colorStart = lighten(lightBlue[300], 0.3);
			colorEnd = lighten(colorEnd, 0.3);
		}
		// Hot
		if (value > 85 && value <= 94) {
			colorStart = lighten(lightBlue[300], 0.3);
			colorEnd = lighten(deepOrange[300], 0.3);
		}
		// Warm
		if (value > 66 && value <= 84) {
			colorStart = lighten(lightBlue[300], 0.3);
			colorEnd = lighten("#C39D5F", 0.3);
		}
		// Average
		if (value > 55 && value <= 65) {
			colorStart = lighten(blue[300], 0.3);
			colorEnd = lighten(lightBlue[500], 0.3);
		}
		// Cool
		if (value <= 54) {
			colorStart = lighten(lightBlue[300], 0.3);
			colorEnd = lighten(colorStart, 0.3);
		}
	}

	return { colorStart, colorEnd };
};
