import React from "react";
import ReactDOM from "react-dom";

// MUI
import { CssBaseline } from "@material-ui/core";

// Firebase
import { ReactReduxFirebaseProvider } from "react-redux-firebase";

// Redux
import { Provider } from "react-redux";
import { rrfProps, store } from "./app/store";

// Components
import App from "./App";

ReactDOM.render(
	<div style={{ height: "100%" }}>
		<CssBaseline />
		<Provider store={store}>
			<ReactReduxFirebaseProvider {...rrfProps}>
				<App />
			</ReactReduxFirebaseProvider>
		</Provider>
	</div>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
