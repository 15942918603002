import * as React from "react";

// MUI
import {
	Box,
	Divider,
	Grid,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
} from "@material-ui/core";
import Slider from "@mui/material/Slider";

// Icons
import { ReactComponent as Humidity } from "../../../../static/svg/humidity.svg";
import { ReactComponent as Fan } from "../../../../static/svg/fan_on.svg";

// UTILS
import { find } from "lodash";

// Firebase
import { functions } from "../../../../firebase/firebase";
import { useSelector } from "react-redux";
const postEndpoint = functions.httpsCallable("postEndpoint");

// Utility Functions
function valuetext(value: number) {
	return `${value}°F`;
}

// Utility Variables

const minDistance = 2;

const marks = [
	{
		value: 65,
		label: "65°F",
	},
	{
		value: 70,
		label: "70°F",
	},
	{
		value: 75,
		label: "75°F",
	},
	{
		value: 80,
		label: "80°F",
	},
	{
		value: 85,
		label: "85°F",
	},
	{
		value: 90,
		label: "90°F",
	},
];

// Interfaces
interface ITemperatureControlsProps {
	deviceData: any;
}

export const TemperatureControls = ({
	deviceData,
}: ITemperatureControlsProps) => {
	const api = useSelector((state: any) => state.firebase.profile.api);
	const user = useSelector((state: any) => state.firebase.profile.email);

	// Post
	interface IEndpointPayload {
		id: string;
		value: number;
		user: string;
		api: string;
	}

	//-		 			Device Points 				//
	//----------------------------------------------//
	//1 		Temperature Value Point				//
	// const tempValuePoint = find(deviceData?.points, [
	// 	"virtualDeviceSlot",
	// 	"tempDisplay",
	// ]);

	//2 		Temperature Status Point			//
	// const tempStatusPoint = find(deviceData?.points, [
	// 	"virtualDeviceSlot",
	// 	"status",
	// ]);

	//3 		Humidity Status          			//
	const humidityPoint = find(deviceData?.points, [
		"virtualDeviceSlot",
		"humidity",
	]);

	//4 		Humidity Status          			//
	const fanSpeedPoint = find(deviceData?.points, [
		"virtualDeviceSlot",
		"fanSpeed",
	]);

	//.				NOTE ABOUT SETPOINTS			//
	//. -------------------------------------------	//
	//.		The COOLING setpoint will be higher 	//
	//. 	than the HEATING. Think of these points	//
	//.		as temps at which to engage heat / cool	//

	//3 		Cooling Setpoint Point				//
	const coolingSetpointPoint = find(deviceData?.points, [
		"virtualDeviceSlot",
		"tempDecrease",
	]);

	//4 		Heating Setpoint Point				//
	const heatingSetpointPoint = find(deviceData?.points, [
		"virtualDeviceSlot",
		"tempIncrease",
	]);

	//5			Single Setpoint Point 				//
	const singleSetpointPoint = find(deviceData?.points, [
		"virtualDeviceSlot",
		"setpoint",
	]);

	//1 		Temperature Value					//
	// const tempValue =
	// 	typeof tempValuePoint?.data?.value === "number"
	// 		? Math.round(tempValuePoint?.data?.value)
	// 		: 0;

	//2 		Temperature Status					//
	// const tempStatus =
	// 	typeof tempStatusPoint?.data?.displayString === "string"
	// 		? tempStatusPoint.data.displayString.toLowerCase()
	// 		: "";

	//3 		Cooling Setpoint					//
	const coolingSetpoint =
		typeof coolingSetpointPoint?.data?.value === "number"
			? Math.round(coolingSetpointPoint?.data?.value)
			: 0;

	//4 		Heating Setpoint					//
	const heatingSetpoint =
		typeof heatingSetpointPoint?.data?.value === "number"
			? Math.round(heatingSetpointPoint?.data?.value)
			: 0;
	//5			Single Setpoint						//
	const singleSetpoint =
		typeof singleSetpointPoint?.data?.value === "number"
			? Math.round(singleSetpointPoint?.data?.value)
			: null;

	// Single / multi switch
	const isSingleSP = Boolean(singleSetpoint);

	// Multi Switch
	const [values, setValues] = React.useState<number[]>([
		heatingSetpoint,
		coolingSetpoint,
	]);

	// Single Switch
	const [value, setValue] = React.useState<number | undefined>(
		typeof singleSetpoint === "number" ? singleSetpoint : undefined
	);

	const [activeSetpoint, setActiveSetpoint] = React.useState("");

	// Multi Change Handler
	const handleChangeValues = (
		event: Event,
		newValue: number | number[],
		activeThumb: number
	) => {
		if (!Array.isArray(newValue)) {
			return;
		}

		if (activeThumb === 0) {
			// 3    Heating     //

			setActiveSetpoint("heating");
			setValues([Math.min(newValue[0], values[1] - minDistance), values[1]]);
		} else {
			// 2    Cooling     //

			setActiveSetpoint("cooling");
			setValues([values[0], Math.max(newValue[1], values[0] + minDistance)]);
		}
	};

	// Single Change Handler
	const handleChangeValue = (
		event: Event,
		newValue: number | number[],
		activeThumb: number
	) => {
		if (typeof newValue === "number" && typeof value === "number") {
			setValue(newValue);
		}
	};

	const commitChange = (
		event: React.SyntheticEvent | Event,
		newValue: number | Array<number>
	) => {
		if (Array.isArray(newValue)) {
			if (activeSetpoint === "heating") {
				postEndpoint({
					api,
					user,
					route: `point/${heatingSetpointPoint?.id}`,
					payload: values[0],
				}).catch((e: any) => {
					console.log(e);
				});
			} else {
				postEndpoint({
					api,
					user,
					route: `point/${coolingSetpointPoint?.id}`,
					payload: values[1],
				}).catch((e: any) => {
					console.log(e);
				});
			}
		} else if (isSingleSP) {
			postEndpoint({
				api,
				user,
				route: `point/${singleSetpointPoint?.id}`,
				payload: value,
			}).catch((e: any) => {
				console.log(e);
			});
		}
	};

	return (
		<Grid container>
			{/* Adjust Setpoints */}
			<Grid item xs={12}>
				{/* MULTI */}
				{Boolean(heatingSetpoint && coolingSetpoint && !isSingleSP) && (
					<Box>
						<Typography>Thermostat Setpoints</Typography>
						<Slider
							min={65}
							max={90}
							getAriaLabel={() => "Cooling Setpoints"}
							value={values}
							onChange={handleChangeValues}
							onChangeCommitted={commitChange}
							valueLabelDisplay="auto"
							step={1}
							marks={marks}
							getAriaValueText={valuetext}
							disableSwap
						/>
					</Box>
				)}

				{/* SINGLE */}
				{Boolean(isSingleSP) && (
					<Box>
						<Typography>Thermostat Setpoint</Typography>
						<Slider
							min={65}
							max={90}
							getAriaLabel={() => "Setpoint"}
							value={value}
							onChange={handleChangeValue}
							onChangeCommitted={commitChange}
							valueLabelDisplay="auto"
							step={1}
							marks={marks}
							getAriaValueText={valuetext}
							disableSwap
						/>
					</Box>
				)}
			</Grid>

			{/* Additional Info */}
			<Divider style={{ width: "100%", margin: "1rem 0" }} />

			{/* Fan Speed */}
			{Boolean(fanSpeedPoint?.data?.displayString) && (
				<Grid item xs={12} sm={6}>
					<ListItem disableGutters>
						<ListItemAvatar style={{ display: "flex" }}>
							<Fan height={30} />
						</ListItemAvatar>
						<ListItemText
							primary={fanSpeedPoint?.data?.displayString}
							secondary="Fan Speed"
						/>
					</ListItem>
				</Grid>
			)}

			{/* Humidity */}
			{Boolean(humidityPoint?.data?.displayString) && (
				<Grid item xs={12} sm={6}>
					<ListItem disableGutters>
						<ListItemAvatar style={{ display: "flex" }}>
							<Humidity height={30} />
						</ListItemAvatar>
						<ListItemText
							primary={humidityPoint?.data?.displayString}
							secondary="Humidity"
						/>
					</ListItem>
				</Grid>
			)}
		</Grid>
	);
};
