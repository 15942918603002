import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { find } from "lodash";
import * as React from "react";
import { LightSlider, LightSwitch } from "..";

// Redux
import { useAppSelector } from "../../../../app/hooks";

// Interfaces
import { RootState } from "../../../../app/store";
import usePollDevice from "../../../../hooks/useDeviceInterval";
import { GlassCard } from "../../../GlassCard/GlassCard";

interface ILightingCardProps {
	lightingDevice: any;
}

export const LightingCard: React.FC<ILightingCardProps> = ({
	lightingDevice,
}) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.only("xs"));

	// Building data
	const deviceStore = useAppSelector((state: RootState) => state.devices); //devices fetched and stored on selection
	const deviceData = deviceStore.data?.[lightingDevice.id];

	const [pollData] = usePollDevice({
		deviceData,
		route: `device/${lightingDevice.id}`,
	});

	// If no device data or the device has no points, return null;
	if (Boolean(!deviceData || !deviceData?.points?.length)) {
		return null;
	}

	const lightPoint =
		find(pollData?.points, ["virtualDeviceSlot", "lightSwitch"]) ||
		find(deviceData.points, ["virtualDeviceSlot", "lightSwitch"]);

	// Convenience (switch vs. slider)
	const lightType = {
		switch: Boolean(lightPoint?.dataType === "boolean"),
		slider: Boolean(lightPoint?.dataType === "number"),
	};

	return (
		<GlassCard title={lightPoint.name}>
			<Grid container>
				{Boolean(lightType.switch) && <LightSwitch pointData={lightPoint} />}
				{mobile && (
					<span style={{ width: "100%", display: "flex", height: "20px" }} />
				)}
				{Boolean(lightType.slider) && <LightSlider pointData={lightPoint} />}
			</Grid>
		</GlassCard>
	);
};
