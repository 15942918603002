import * as React from "react";

// Lab
import { Skeleton } from "@material-ui/lab";

// Redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../app/hooks";
import { fetchDevice } from "../../../../features/devices/devicesSlice";

// Interfaces
import { RootState } from "../../../../app/store";
import { IPoint } from "../../../../interfaces";
import usePollDevice from "../../../../hooks/useDeviceInterval";

interface IDeviceData {
	id: string;
	name: string;
	points: IPoint | any[];
	virtualProfile: string;
}

interface IDeviceLoader {
	device: IDeviceData;
	buildingId: string;
	floorId: string;
	spaceId: string;
	children: any;
}

export const DeviceLoader: any = ({
	device,
	buildingId,
	floorId,
	spaceId,
	children,
}: IDeviceLoader) => {
	const dispatch = useDispatch();

	const api = useAppSelector((state: RootState) => state.firebase.profile.api);

	// Device data
	const deviceStore = useAppSelector((state: RootState) => state.devices); //devices fetched and stored on selection
	const deviceData = deviceStore.data?.[device.id];

	// // If no device data, load it into the store
	React.useEffect(() => {
		if (!Boolean(deviceData) && deviceStore.status !== "loading") {
			dispatch(
				fetchDevice({ api, id: device.id, buildingId, floorId, spaceId })
			);
		}
		// eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		const interval = setInterval(() => {
			if (Boolean(device?.id)) {
				dispatch(
					fetchDevice({ api, id: device.id, buildingId, floorId, spaceId })
				);
			}
		}, 20000);

		return () => {
			clearInterval(interval);
		};

		// eslint-disable-next-line
	}, [device?.id]);

	return <>{children}</>;
};
