import React from "react";

// Styles
import {
	createStyles,
	Theme,
	makeStyles,
	useTheme,
} from "@material-ui/core/styles";

// MUI
import {
	AppBar,
	Toolbar,
	Fab,
	SvgIcon,
	useMediaQuery,
} from "@material-ui/core";

// Icons
import { ReactComponent as Home } from "../../../static/svg/home.svg";
import { ReactComponent as Spaces } from "../../../static/svg/spaces.svg";

import { GlassPaper } from "../../GlassPaper/GlassPaper";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			top: "auto",
			bottom: 0,
		},
		toolbar: {
			display: "flex",
			justifyContent: "space-evenly",
			padding: "1rem",
		},
		fabButton: {
			margin: "0 auto",
		},
	})
);

export const DashboardAppBarBottom: any = (props: any) => {
	const classes = useStyles();
	const history = useHistory();

	const { pathname } = history?.location;

	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.only("xs"));

	return (
		<AppBar position="fixed" color="transparent" className={classes.appBar}>
			<GlassPaper
				square
				style={{ position: "fixed", width: "100%", bottom: 0 }}
			>
				<Toolbar className={classes.toolbar}>
					<Fab
						size={mobile ? "small" : "medium"}
						color={pathname === "/home" ? "primary" : "default"}
						aria-label="add"
						className={classes.fabButton}
						onClick={() => history.push("/home")}
					>
						<SvgIcon style={{ fontSize: mobile ? "1rem" : "1.25rem" }}>
							<Home />
						</SvgIcon>
					</Fab>

					<Fab
						size={mobile ? "small" : "medium"}
						color={pathname === "/spaces" ? "primary" : "default"}
						aria-label="add"
						className={classes.fabButton}
						onClick={() => history.push("/spaces")}
					>
						<SvgIcon style={{ fontSize: mobile ? "1rem" : "1.25rem" }}>
							<Spaces />
						</SvgIcon>
					</Fab>
				</Toolbar>
			</GlassPaper>
		</AppBar>
	);
};
