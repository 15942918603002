import * as React from "react";

import { GlassDial } from "../../../GlassDial/GlassDial";
import { Grid, useTheme, useMediaQuery } from "@material-ui/core";

// Firebase
import { functions } from "../../../../firebase/firebase";
import { useSelector } from "react-redux";
const postEndpoint = functions.httpsCallable("postEndpoint");

interface ILightSwitchProps {
	pointData: any;
}

export const LightSwitch = ({ pointData }: ILightSwitchProps) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.only("xs"));

	const api = useSelector((state: any) => state.firebase.profile.api);
	const user = useSelector((state: any) => state.firebase.profile.email);

	let [value, setValue] = React.useState(pointData?.data?.value);

	const commitChange = (
		event: React.SyntheticEvent | Event,
		newValue: number | Array<number>
	) => {
		postEndpoint({
			api,
			user,
			route: `point/${pointData?.id}`,
			payload: !value,
		}).catch((e: any) => null);
		setValue(!value);
	};

	return (
		<Grid container>
			<Grid
				item
				xs={12}
				sm={4}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<GlassDial
					id={pointData?.id}
					value={pointData?.data?.value}
					handleClick={commitChange}
				/>
			</Grid>
			{mobile && (
				<span style={{ width: "100%", display: "flex", height: "20px" }} />
			)}
		</Grid>
	);
};
