import * as React from "react";

// Mui
import {
	Box,
	// Styles
	createStyles,
	makeStyles,
	Theme,
} from "@material-ui/core";

// Util
import clsx from "clsx";

// Styles
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// App Bar
		glass: {
			display: "inline-flex",
			backgroundColor: "transparent",
			backgroundImage:
				"linear-gradient(to bottom right, rgba(255, 255, 255, .2), rgba(255, 255, 255, 0))",
			backdropFilter: "blur(7px)",
			boxShadow: "10px 10px 10px rgba(30, 30, 30, .1)",
			borderRadius: "100%",
		},
		fullWidth: {
			width: "100%",
		},
		round: {},
		shadows: {
			boxShadow:
				"inset -3px -3px 10px -10px rgba(255, 255, 255, 1), inset 0px 0px 10px rgba(13, 39, 80, .3)",
			filter:
				"drop-shadow(3px 3px 10px rgba(13, 39, 80, .5)) drop-shadow(-2px -2px 4px rgba(255, 255, 255, 1))",
		},
	})
);

interface IGlassDial {
	children: any;
	style?: any;
}

export const GlassCircle: React.FC<IGlassDial> = (props) => {
	const classes = useStyles();

	const { style } = props;

	return (
		<div className={clsx(classes.glass)} style={style}>
			<Box position="relative" display="inline-flex">
				{props.children}
			</Box>
		</div>
	);
};
