import * as React from "react";

// Components
import { GlassPaper } from "../GlassPaper/GlassPaper";
import { GlassPaperTitle } from "../GlassPaperTitle/GlassPaperTitle";

interface IGlassCard {
	children: any;
	title?: any;
	action?: any;
}

export const GlassCard = ({ children, title }: IGlassCard) => {
	return (
		<GlassPaper style={{ padding: "2rem", height: "100%" }} square>
			{Boolean(title) && (
				<GlassPaperTitle title={typeof title === "string" ? title : void 0}>
					{typeof title !== "string" ? title : null}
				</GlassPaperTitle>
			)}

			{children}
		</GlassPaper>
	);
};
